import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  Badge,
  Button,
  InputGroup,
  FormControl,
  Row,
  Col,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Pagination from "../../../components/Pagination";
import { listPaginatedProduct } from "../../../api/nebProductService";
import { Body, Cell, Head, Row as RowClay } from "@clayui/core";
import ClayCard from "@clayui/card";
import ClayButton from "@clayui/button";
import ClayInput from "@clayui/form/lib/Input";
import ClayLayout from "@clayui/layout";

const ProductList = () => {
  const initialPage = 1;
  const initialItemsPerPage = 5;

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);
  const [productList, setProductList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await listPaginatedProduct(
          currentPage - 1,
          itemsPerPage,
          searchTerm
        );
        setProductList(result.content);
        setTotalPages(result.totalPages);
        setTotalItems(result.totalElements);
        console.log("productlist: " + JSON.stringify(productList, null, 2));
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [currentPage, itemsPerPage, searchTerm]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update search term on input change
    setCurrentPage(1); // Reset to first page when searching
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy h:mm:ss aa");
  };

  return (
    <>
      <style>
        {`
          .custom-pagination-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
          }
          .items-per-page {
            display: flex;
            align-items: center;
          }
        `}
      </style>
      <ClayCard className="mb-3" style={{ borderRadius: "20px" }}>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <ClayLayout.Row className="w-100">
            <ClayLayout.Col md={6}>
              <ClayInput.Group>
                <ClayInput.GroupItem>
                  <ClayInput
                    placeholder="Search Product Name"
                    aria-label="Search"
                    aria-describedby="button-addon2"
                    value={searchTerm} // Bind input to search term state
                    onChange={handleSearchChange} // Handle input change
                  />
                </ClayInput.GroupItem>
              </ClayInput.Group>
            </ClayLayout.Col>
            <ClayLayout.Col md={6} className="d-flex justify-content-end">
              <Link to="/createNewProduct">
                <ClayButton displayType="primary" className="mb-2 mb-md-0">
                + Add New Product
                </ClayButton>
              </Link>
            </ClayLayout.Col>
          </ClayLayout.Row>
        </Card.Header>
        <Card.Body className="p-3" style={{ overflowX: "auto" }}>
          <Table striped bordered hover size="sm" className="mb-2">
            <thead className="text-white">
              <tr>
                <th style={{ width: "5%" }}>No</th>
                <th style={{ width: "35%" }}>Product Category</th>
                <th style={{ width: "35%" }}>Product Name</th>
                <th style={{ width: "15%" }}>Date Created</th>
                <th style={{ width: "10%" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {productList.map((d, i) => (
                <tr key={d.id}>
                  <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                  <td>{d.nebProductCategory.productCategory}</td>
                  <td>{d.productName}</td>
                  <td>{formatDate(d.createdDate)}</td>
                  <td>
                    <Link
                      to={`/productById/${d.id}`}
                      className="btn btn-link"
                      style={{ width: "80px" }}
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems} // Pass totalItems to Pagination
            onPageChange={handlePageChange}
            onEntriesChange={handleItemsPerPageChange}
            entriesPerPage={itemsPerPage}
          />
        </Card.Body>
      </ClayCard>
    </>
  );
};

export default ProductList;
