import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {getAgencyById} from "../../../api/nebAgencyService";
import {getDataProviderListByAgencyId} from "../../../api/nebDataProviderService";
import {getLookupParameterDetailsByCode} from "../../../api/lookupService";

function AgencyById() {
  const { id } = useParams();
  const [agencyDetails, setAgencyDetails] = useState(null); 
  const [sectorDescription, setSectorDescription] = useState(null); 
  const [latestDataProvider, setLatestDataProvider] = useState(null);

  useEffect(() => {
    const fetchAgencyDetails = async () => {
      try {
        const response = await getAgencyById(id);
        setAgencyDetails(response);
        console.log("response: ", response);

        // Get sector description based on sectorId
        const sectorResponse = await getLookupParameterDetailsByCode(response.sectorId);
        setSectorDescription(sectorResponse);
        console.log("sector description: ", sectorResponse);
      } catch (error) {
        console.error("Error fetching agency details:", error);
      }
    };

    fetchAgencyDetails();
  }, [id]);

  useEffect(() => {
    const fetchLatestDataProvider = async (id) => {
      try {
        const response = await getDataProviderListByAgencyId(id);
        if (response.length > 0) {
          const activeProvider = response.find(provider => provider.status === "ACTIVE");
          if (activeProvider) {
            setLatestDataProvider(activeProvider);
          } else {
            const pendingProviders = response.filter(provider => provider.status === "PENDING");
            if (pendingProviders.length > 0) {
              const sortedPendingProviders = pendingProviders.sort((a, b) => new Date(b.updatedDate) - new Date(a.updatedDate));
              setLatestDataProvider(sortedPendingProviders[0]);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching Data Provider details:", error);
      }
    };

    fetchLatestDataProvider(id);
  }, [id]);

  const isSameAsContactPerson = latestDataProvider && 
    agencyDetails &&
    latestDataProvider.name === agencyDetails.cpdName &&
    latestDataProvider.email === agencyDetails.cpdEmail &&
    latestDataProvider.phoneNo === agencyDetails.cpdPhoneNumber;

  return (
    <>
        <div className="text-center p-1 mb-3 d-flex justify-content-end">
          <Link to={`/neb-agency/neb-agency-form-wizard/${id}`} className="btn btn-primary">
            Update Details
          </Link>
        </div>
        {agencyDetails && (
          <div className="card">
            <div className="card-header">
              <h4>Data Provider Summary</h4>
              <p>
                Check all the details before creating data provider's account
              </p>
            </div>
            <div className="card-body">
              <div className="row mb-2">
                <h5>
                  <strong>Agency Details</strong>
                </h5>
              </div>
              <div className="row mb-4">
                <div className="col-sm-3">
                  <strong>ENERGY SECTOR</strong>
                  <br />
                  {sectorDescription ? sectorDescription.description : "Loading..."}
                </div>
                <div className="col-sm-3">
                  <strong>AGENCY</strong>
                  <br />
                  {agencyDetails.agencyName}
                </div>
                <div className="col-sm-3">
                  <strong>OFFICE NUMBER</strong>
                  <br />
                  {agencyDetails.phoneNumber}
                </div>
                <div className="col-sm-3">
                  <strong>ADDRESS</strong>
                  <br />
                  {agencyDetails.address1}, {agencyDetails.address2},{" "}
                  {agencyDetails.address3}
                </div>
              </div>
              <div className="row mb-2">
                <h5>
                  <strong>Contact Person details</strong>
                </h5>
              </div>
              <div className="row mb-4">
                <div className="col-sm-3">
                  <strong>NAME</strong>
                  <br />
                  {agencyDetails.cpdName}
                </div>
                <div className="col-sm-3">
                  <strong>EMAIL</strong>
                  <br />
                  {agencyDetails.cpdEmail}
                </div>
                <div className="col-sm-3">
                  <strong>PHONE NUMBER</strong>
                  <br />
                  {agencyDetails.cpdPhoneNumber}
                </div>
              </div>
              <div className="row mb-2">
                <h5>
                  <strong>Data Provider Details</strong>
                </h5>
              </div>
              {latestDataProvider ? (
                <div className="row mb-4">
                  {isSameAsContactPerson ? (
                    <div className="col-sm-12">
                      Data Provider Details same with Contact Person Details
                    </div>
                  ) : (
                    <>
                      <div className="col-sm-3">
                        <strong>NAME</strong>
                        <br />
                        {latestDataProvider.name}
                      </div>
                      <div className="col-sm-3">
                        <strong>EMAIL</strong>
                        <br />
                        {latestDataProvider.email}
                      </div>
                      <div className="col-sm-3">
                        <strong>PHONE NUMBER</strong>
                        <br />
                        {latestDataProvider.phoneNo}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="row mb-4">
                  <div className="col-sm-3">
                    <strong>NAME</strong>
                    <br />
                    No data provider available
                  </div>
                </div>
              )}
              <div className="row mb-2">
                <h5>
                  <strong>Product List</strong>
                </h5>
              </div>
              <div className="row mb-4">
                <div>
                  {agencyDetails.categories.map((category) =>
                    category.products.map((product) => (
                      <tr key={product.id}>
                        <td>{product.productName}</td>
                      </tr>
                    ))
                  )}
                </div>
              </div>
              <div className="row mb-2">
                <h5>
                  <strong>Form List</strong>
                </h5>
              </div>
              <div className="row mb-4">
                <div className="col-sm-12">
                  {agencyDetails.forms.map((form) => (
                    <div key={form.id} className="mb-3">
                      {form.formDescription}
                      <div>
                        {form.formFields.map((formField) => (
                          <span key={formField.id} className="badge bg-primary me-1 mr-1">
                            {formField.formFieldName}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
}

export default AgencyById;
