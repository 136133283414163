import React from 'react';

const CustomLegend = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
        <div style={{
          width: '12px',
          height: '12px',
          backgroundColor: '#169b32',
          marginRight: '6px',
          borderRadius: '50%'
        }}></div>
        <span style={{ fontSize: '12px' }}>Approved</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
        <div style={{
          width: '12px',
          height: '12px',
          backgroundColor: '#ffae00',
          marginRight: '6px',
          borderRadius: '50%'
        }}></div>
        <span style={{ fontSize: '12px' }}>Pending for Approval</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{
          width: '12px',
          height: '12px',
          backgroundColor: '#ff6060',
          marginRight: '6px',
          borderRadius: '50%'
        }}></div>
        <span style={{ fontSize: '12px' }}>Pending for Amendment</span>
      </div>
    </div>
  );
};

export default CustomLegend;
