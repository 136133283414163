import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
// import "../../App.css";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllProductCategory } from "../../../api/nebProductCategoryService";
import { getUnitByCategoryId } from "../../../api/nebProductCategoryUnitLookupService";
import {
  getProductById,
  updateProduct,
  saveProduct,
} from "../../../api/nebProductService";
import {
  deleteProductUnitsByProductId,
  saveProductUnit,
} from "../../../api/nebProductUnitService";

function CreateNewProduct() {
  const { id } = useParams();
  const [showCreateAcc, setShowCreateAcc] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(true);
  const [productCategoryListing, setProductCategoryListing] = useState([]);
  const [unitRows, setUnitRows] = useState([{ unit: "", conversionValue: "" }]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [productName, setProductName] = useState("");
  const [unit, setUnit] = useState("");
  const [conversionValue, setConversionValue] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchProductCategory();
    if (id) {
      fetchProductDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (selectedCategory) {
      fetchUnitsByCategory(selectedCategory);
    } else {
      setUnitRows([{ unit: "", conversionValue: "" }]); // Reset rows if category is changed
    }
  }, [selectedCategory]);

  const fetchProductCategory = async () => {
    try {
      const result = await getAllProductCategory();
      setProductCategoryListing(result);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUnitsByCategory = async (categoryId) => {
    try {
      const result = await getUnitByCategoryId(categoryId);
      if (!categoryId || result.length === 0) {
        setUnitRows([{ unit: "", conversionValue: "" }]);
      }
      setUnitOptions(result); 
    } catch (error) {
      console.log(error);
    }
  };
  

  const fetchProductDetails = async (productId) => {
    try {
      const response = await getProductById(productId);
      setSelectedCategory(response.nebProductCategory.id);
      setProductName(response.productName);

      const units = response.nebProductUnits.map((unit) => ({
        unit: unit.productUnitName,
        conversionValue: unit.inKtoe,
      }));
      setUnitRows(units);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  const handleAddRow = () => {
    setUnitRows([...unitRows, { unit: "", conversionValue: "" }]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...unitRows];
    updatedRows.splice(index, 1);
    setUnitRows(updatedRows);
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...unitRows];
    updatedRows[index][field] = value;
    setUnitRows(updatedRows);
  };

  const handleCreateOrUpdateProduct = async () => {
    try {
      for (let i = 0; i < unitRows.length; i++) {
        const numericConversionValue = parseFloat(unitRows[i].conversionValue);
        if (isNaN(numericConversionValue)) {
          toast.error("Conversion value must be a number.");
          return;
        }
      }

      const product = {
        productCategoryId: selectedCategory,
        productName: productName,
      };

      let savedProduct;
      if (id) {
        product.id = id;
        savedProduct = await updateProduct(product);
        await deleteProductUnitsByProductId(product.id);
      } else {
        savedProduct = await saveProduct([product]);
      }

      if (savedProduct && savedProduct.id) {
        const productUnits = unitRows.map((row) => ({
          productId: savedProduct.id,
          productUnitName: row.unit,
          inKtoe: row.conversionValue,
        }));

        await saveProductUnit(productUnits);

        toast('The product have been saved successfully!', {
          position: "top-center", // Adjust as needed
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce, // Ensure Bounce is imported
        });

        setTimeout(() => {
          navigate(`/productById/${savedProduct.id}`);
        }, 2000);
      } else if (savedProduct.length > 0) {
        const productUnits = unitRows.map((row) => ({
          productId: savedProduct[0].id,
          productUnitName: row.unit,
          inKtoe: row.conversionValue,
        }));

        await saveProductUnit(productUnits);

        toast('The product have been saved successfully!', {
          position: "top-center", // Adjust as needed
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce, // Ensure Bounce is imported
        });

        setTimeout(() => {
          navigate(`/managementDashboard`);
        }, 2000);
      } else {
        throw new Error("Failed to save the product or missing product ID.");
      }
    } catch (error) {
      console.error("Error saving the details:", error);
      toast.error("Error saving the details");
    }
  };

  const handleCancel = () => {
    const confirmation = window.confirm(
      "Are you sure you want to cancel this process?"
    );
    if (confirmation) {
      toast("The process have been cancel", {
        position: "top-center", // Adjust as needed
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce, // Ensure Bounce is imported
      });
      setTimeout(() => {
        navigate(`/neb-product/neb-product-list`);
      }, 2000);
    }
  };

  const handleCloseAccount = () => {
    navigate("/CreateNewProductSummary");
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header">
              <h2
                className="card-title"
                style={{ fontSize: "24px", fontWeight: "bold" }}
              >
                {id ? "Update Product" : "Create Products"}
              </h2>
              <div style={{ clear: "both" }}></div>
              <h6>Fill up all details*</h6>
            </div>

            <div className="card-body">
              <label htmlFor="energySectorInput" style={{ color: "blue" }}>
                Product Details
              </label>
              <div className="row">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label htmlFor="CpProductCategory">Product Category</label>
                    <select
                      className="form-control select2"
                      style={{ width: "100%" }}
                      id="CpProductCategory"
                      name="CpProductCategory"
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Item...
                      </option>
                      {productCategoryListing.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.productCategory}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-lg-5">
                  <div className="form-group">
                    <label>Product Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="CpProductName"
                      placeholder="Enter product name"
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              {/* Render dynamic unit rows */}
              {unitRows.map((row, index) => (
                <div className="row" key={index}>
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label>Unit</label>
                      <select
                        className="form-control"
                        id={`CpUnit${index}`}
                        value={row.unit}
                        onChange={(e) =>
                          handleRowChange(index, "unit", e.target.value)
                        }
                      >
                        <option value="" disabled>
                          Select Unit...
                        </option>
                        {unitOptions.map((unitOption) => (
                          <option key={unitOption.id} value={unitOption.unit}>
                            {unitOption.unit}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label>Conversion</label>
                      <input
                        type="number"
                        className="form-control"
                        id={`CpConversion${index}`}
                        placeholder="Enter conversion factor"
                        value={row.conversionValue}
                        onChange={(e) =>
                          handleRowChange(
                            index,
                            "conversionValue",
                            e.target.value.replace(/[^0-9.]/g, "")
                          )
                        } // Allow only numbers
                      />
                      <small className="form-text text-muted">
                        {row.unit
                          ? `1 ${row.unit} = ${
                              row.conversionValue || "______"
                            } ktoe`
                          : "Select a unit to see the conversion"}
                      </small>
                    </div>
                  </div>

                  <div className="col-lg-2">
                    <div className="form-group">
                      {index !== 0 && (
                        <Button
                          color="danger"
                          onClick={() => handleRemoveRow(index)}
                          style={{ marginTop: "30px" }}
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              <div className="row">
                <div className="col-lg-12">
                  <button
                    className="btn btn-primary"
                    onClick={handleAddRow}
                    style={{ marginBottom: "10px" }}
                  >
                    Add Row
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Modal isOpen={showCreateAcc} toggle={handleCloseAccount}>
            <ModalHeader toggle={handleCloseAccount}>
              {id ? "Product Updated" : "Product Created"}
            </ModalHeader>
            <ModalBody>
              <p>
                Your Product has been successfully {id ? "updated" : "created"}!
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleCloseAccount}>
                Close
              </Button>
            </ModalFooter>
          </Modal>

          {buttonsVisible && (
            <div className="d-flex justify-content-end mt-3 mb-3">
              <Link to="/managementDashboard">
                <Button
                  color="link"
                  className="mr-2 btn btn-outline-primary"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Link>
              <Button
                color="primary"
                className="ml-2"
                onClick={handleCreateOrUpdateProduct}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default CreateNewProduct;
