import axiosInstance from './axiosInstance'; // Import the axios instance

const endpoint = "/neb/nebproduct";

export const listPaginatedProduct = async (page, size, searchTerm) => {
  try {
    const response = await axiosInstance.get(`${endpoint}/paginated?page=${page}&size=${size}&searchTerm=${searchTerm}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching paginated products:', error);
    throw error;
  }
};

export const saveProduct = async (product) => {
  try {
    const response = await axiosInstance.post(`${endpoint}/save`, product);
    return response.data;
  } catch (error) {
    console.error('Error saving product:', error);
    throw error;
  }
};

export const updateProduct = async (product) => {
  try {
    const response = await axiosInstance.put(`${endpoint}/update`, product);
    return response.data;
  } catch (error) {
    console.error('Error updating product:', error);
    throw error;
  }
};

export const getProductById = async (id) => {
  try {
    const response = await axiosInstance.get(`${endpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching product by id:', error);
    throw error;
  }
};

export const getProductByCategoryId = async (productCategoryId) => {
  try {
    const response = await axiosInstance.get(`${endpoint}/productByCategoryId/${productCategoryId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching products by category id:', error);
    throw error;
  }
};

export const getProductAndForms = async (agencyId, parentCode) => {
  try {
    const response = await axiosInstance.get(`${endpoint}/productAndFormByAgencyId/${agencyId}/${parentCode}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
