
import ClayPanel from '@clayui/panel';
import faq from "./faq.json";


const FAQ = () => {
  const items = [
    {
      icon: 'tag',
      title: 'Tag',
    },
    {
      divider: true,
      icon: 'message',
      title: 'Message',
    },
    {
      icon: 'effects',
      title: 'Effects',
    },
  ];

  return (
    <div>
      <div>
      {faq.map((item, index) => (
        <ClayPanel
          key={index}
          collapsable
          displayTitle={
            <ClayPanel.Title>
              <h6>{item.FAQ}</h6>
            </ClayPanel.Title>
          }
          displayType="secondary"
          showCollapseIcon={true}
        >
          <ClayPanel.Body>{item.Answer}</ClayPanel.Body>
        </ClayPanel>
      ))}
    </div>
    </div>
  );

};


export default FAQ

