import React, { useState, useCallback, useEffect } from 'react';
import { getProductAndForms } from "../../../../api/nebProductService";
import { ClaySelect, ClayInput } from '@clayui/form';


const C1TableCheckingDP = ({ agencyId, checkingReport, totalSellouts }) => {

    const [headers, setHeaders] = useState([]);
    const [rows, setRows] = useState([]);
    const [inputsPopulated, setInputsPopulated] = useState(false);
    const [productAndFormsList, setProductAndFormsList] = useState([]);


    const numInputStyle = {
        textAlign: "right",
        width: "100%",
        height: "40px",
        marginRight: "5px",
        borderRadius: "5px",
        background: "#f8f8f8",
        color: "black"
    };

    const selectInputStyle = {
        width: "100%",
        height: "40px",
        marginRight: "5px",
        borderRadius: "5px",
        background: "#f8f8f8",
        color: "black"
    }
    const [fuelValues, setFuelValues] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getProductAndForms(agencyId, 'C1-FECS');
                console.log('FETCHING P&F' + JSON.stringify(result, null, 2));
                setProductAndFormsList(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);


    const handleFuelValueChange = (e, index, productName, parentFormFieldCode, formFieldName, subtractive) => {
        const { name, value: inputValue } = e.target;
        let value = isNaN(Number(parseFloat(inputValue))) ? 0 : Number(parseFloat(inputValue));

        // Make value negative based on form type
        if (subtractive) {
            value = -Math.abs(value);
        }

        // Find the product in productList to check if it's standalone
        const product = productAndFormsList.find(p => p.productName === productName);
        const isStandalone = product ? product.standalone : false;

        // Get the totalSellout value if standalone is true
        console.log("tols:" + JSON.stringify(totalSellouts, null, 2))
        let totalSelloutBalance = 0;
        if (isStandalone) {
            const totalSellout = totalSellouts.find(sellout => sellout[productName] !== undefined);
            totalSelloutBalance = totalSellout ? totalSellout[productName] : 0;
        }

        setFuelValues(prevFuelValues => {
            const exists = prevFuelValues.some(item => item.index === index);

            if (exists) {
                return prevFuelValues.map(item => {
                    if (item.index === index) {
                        let formIndex = item.forms.findIndex(form => form.formCode === parentFormFieldCode);
                        let updatedForms;

                        if (formIndex !== -1) {
                            updatedForms = item.forms.map((form, idx) => {
                                if (idx === formIndex) {
                                    let updatedCategories = form.categories.map(category => {
                                        if (category.formCode === name) {
                                            return { formCode: name, value: value, description: formFieldName };
                                        } else {
                                            return category;
                                        }
                                    });

                                    if (!updatedCategories.some(category => category.formCode === name)) {
                                        updatedCategories.push({ formCode: name, value: value, description: formFieldName });
                                    }

                                    return { ...form, categories: updatedCategories };
                                } else {
                                    return form;
                                }
                            });
                        } else {
                            updatedForms = [...item.forms, { formCode: parentFormFieldCode, categories: [{ formCode: name, value: value, description: formFieldName }] }];
                        }

                        // Calculate balance by adding both the form balance and totalSelloutBalance if standalone is true
                        const originalBalance = updatedForms.reduce((sum, form) => {
                            return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
                        }, 0);

                        const balance = originalBalance + (isStandalone ? totalSelloutBalance : 0);

                        return { ...item, forms: updatedForms, balance: balance };
                    } else {
                        return item;
                    }
                });
            } else {
                const newItem = {
                    index: index,
                    balance: value,
                    productName: productName,
                    forms: [
                        {
                            formCode: parentFormFieldCode,
                            categories: [
                                { formCode: name, value: value, description: formFieldName }
                            ]
                        }
                    ]
                };

                const originalBalance = newItem.forms.reduce((sum, form) => {
                    return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
                }, 0);

                const balance = originalBalance + (isStandalone ? totalSelloutBalance : 0);

                return [
                    ...prevFuelValues,
                    { ...newItem, balance: balance }
                ];
            }
        });
    };




    const [productAndUnit, setProductAndUnit] = useState([]);

    const handleUnitChange = (e, index, productName) => {
        const { value: unit } = e.target;

        setProductAndUnit(prevProductAndUnit => {
            // Check if an object with matching productName exists
            const exists = prevProductAndUnit.some(item => item.fieldName === productName);

            if (exists) {
                // Update the existing object
                return prevProductAndUnit.map(item => {
                    if (item.fieldName === productName) {
                        return {
                            ...item,
                            unit: unit
                        };
                    } else {
                        return item;
                    }
                });
            } else {
                // Add a new object
                const newItem = {
                    fieldName: productName,
                    unit: unit
                };

                return [
                    ...prevProductAndUnit,
                    newItem
                ];
            }
        });
    };

    // After productList changes, rows are formed
    useEffect(() => {
        if (productAndFormsList.length === 0) return;
        const dynamicRows = productAndFormsList.map((product, index) => {
            const fuelObject = fuelValues.find(fuel => fuel.index === index + 1);
            return {
                no: index + 1,
                product: product.productName,
                // totalSellout: (<span style={{ "padding-right": "10px", display: "block", textAlign: "right" }}>{totalSellouts.find(item => item[product.productName])?.[product.productName] ?? 0}</span>),
                unit: (
                    <ClaySelect
                        disabled
                        style={selectInputStyle}
                        key={`selectUnit_${product.productName}`}
                        id={`selectUnit_${product.productName}`}
                        onChange={(e) => handleUnitChange(e, index, product.productName)}>
                        <option value="">Select...</option>
                        {product.units.map((unit, unitIndex) => (
                            <option
                                key={unitIndex}
                                value={unit.productUnitName}
                            >
                                {unit.productUnitName}
                            </option>
                        ))}
                    </ClaySelect>
                ),
                ...product.forms.reduce((acc, form) => {
                    acc[form.formFieldCode] = (
                        <ClayInput
                            disabled
                            key={`${index + 1}_${form.formFieldCode}`}
                            id={`num_${product.productName}_${form.formFieldCode}`}
                            style={numInputStyle}
                            type="number"
                            name={form.formFieldCode}
                            onChange={(e) => handleFuelValueChange(e, index + 1, product.productName, form.parentFormFieldCode, form.formFieldName, form.subtractive)}
                        />
                    );
                    return acc;
                }, {}),
                balance: (<span style={{ "padding-right": "10px", display: "block", textAlign: "right" }}>{fuelObject ? fuelObject.balance.toFixed(3) : 0}</span>) // Handle undefined case
            }
        });

        setRows(dynamicRows);
    }, [totalSellouts, productAndFormsList, fuelValues]);

    //After rows changes, headers is formed
    useEffect(() => {
        if (productAndFormsList.length === 0) return;

        const dynamicHeaders = [
            { id: 'no', name: 'No.' },
            { id: 'product', name: 'Product' },
            { id: 'unit', name: 'Unit' },
            { id: 'balance', name: 'Balance' },
            ...productAndFormsList[0].forms.map(form => ({
                id: form.formFieldCode,
                name: `${form.formFieldName} 
        (${form.parentFormFieldDesc})`
            })),
            // totalSellouts
            //   ?
            //   { id: 'totalSellout', name: 'Total Sell Out' }
            //   : null,
        ];
        setHeaders(dynamicHeaders);
        console.log("dynamicHeaders: " + JSON.stringify(dynamicHeaders, null, 2))
    }, [rows]);

    //after finish forming rows and headers, try fetch report. if got result, fill into the inputs.
    useEffect(() => {
        if (checkingReport && productAndFormsList.length > 0 && !inputsPopulated) {
            console.log("received: " + JSON.stringify(productAndFormsList, null, 2));
            // Iterate through the forms and categories in fetchedData
            checkingReport.forms.forEach((form) => {
                form.categories.forEach((category) => {
                    category.products.forEach((product) => {

                        // NUMERICAL INPUTS
                        // Construct the id to match the <input> elements
                        const numInputId = `num_${product.fieldName}_${category.formCode}`;
                        console.log("received: " + numInputId);
                        const numInputElement = document.getElementById(numInputId);

                        if (numInputElement) {
                            const value = product.value;
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
                            nativeInputValueSetter.call(numInputElement, value);

                            // Dispatch the 'input' event to trigger onChange in React
                            const event = new Event('input', { bubbles: true });
                            numInputElement.dispatchEvent(event);
                        }

                        // DROPDOWN INPUTS
                        // Construct the id to match the <select> elements
                        const selectInputId = `selectUnit_${product.fieldName}`;
                        const selectElement = document.getElementById(selectInputId);

                        if (selectElement) {
                            const value = product.unit;
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, 'value').set;
                            nativeInputValueSetter.call(selectElement, value);

                            // Dispatch the 'input' event to trigger onChange in React
                            const event = new Event('change', { bubbles: true });
                            selectElement.dispatchEvent(event);
                        }
                    });
                });
            });
            setInputsPopulated(true);
        }
    }, [checkingReport, headers]);

    //changes in F4-SEU standalone product in FB will be detected here for balance calculation since cannot be detected in handleFuelValueChange
    // useEffect(() => {
    //     console.log("totalsellouts: " + JSON.stringify(totalSellouts, null, 2))
    //     setFuelValues(prevFuelValues => {
    //         return prevFuelValues.map(item => {
    //             const product = productAndFormsList.find(p => p.productName === item.productName);
    //             const isStandalone = product ? product.standalone : false;

    //             let totalSelloutBalance = 0;
    //             if (isStandalone) {
    //                 const totalSellout = totalSellouts.find(sellout => sellout[product.productName] !== undefined);
    //                 totalSelloutBalance = totalSellout ? totalSellout[product.productName] : 0;
    //             }

    //             const updatedBalance = item.forms.reduce((sum, form) => {
    //                 return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
    //             }, 0) + (isStandalone ? totalSelloutBalance : 0);

    //             return { ...item, balance: updatedBalance };
    //         });
    //     });
    // }, [totalSellouts]);

    return (
        <div class="table-responsive">
            <table class="table-md table-bordered">
                <thead>
                    <tr>
                        {headers.map((column) => (
                            <th
                                key={column.id}
                                className={
                                    column.id === 'no' || column.id === 'balance' || column.id === 'totalSellout' ?
                                        "table-cell-no-width"
                                        : "table-cell-minw-200"}
                                style={
                                    column.id === 'product'
                                        ? {
                                            position: 'sticky',
                                            left: 0,  // First sticky column
                                            backgroundColor: 'white',
                                            zIndex: 1,  // Ensure it's above other cells
                                        }
                                        : column.id === 'balance'
                                            ? {
                                                position: 'sticky',
                                                left: '199px',  // Adjust based on column width
                                                backgroundColor: 'white',
                                                zIndex: 1,  // Ensure it's above other cells
                                            }
                                            : {}
                                }
                            >
                                {column.name}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {rows.map(row => (
                        <tr key={row.no}>
                            {headers.map(column => (
                                <td
                                    key={column.id}
                                    className={
                                        column.id === 'no' || column.id === 'balance' || column.id === 'totalSellout' ?
                                            "table-cell-no-width"
                                            : "table-cell-minw-200"}
                                    style={
                                        column.id === 'product'
                                            ? {
                                                position: 'sticky',
                                                left: 0,  // First sticky column
                                                backgroundColor: 'white',
                                                zIndex: 1,  // Ensure it's above other cells
                                            }
                                            : column.id === 'balance'
                                                ? {
                                                    position: 'sticky',
                                                    left: '199px',  // Adjust based on column width
                                                    backgroundColor: 'white',
                                                    zIndex: 1,  // Ensure it's above other cells
                                                }
                                                : {}
                                    }
                                >
                                    {row[column.id]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
};

export default C1TableCheckingDP;
