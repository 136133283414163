import React, { useEffect, useState } from "react";
import { Card, Button, Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faUsers,
  faTrash,
  faEdit,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { getAnnouncements, updateAnnouncement, deleteAnnouncement } from "../../../api/nebAnnouncementService";
import useAuthStore from "../../../store/useAuthStore";
import { toast } from "react-toastify";

function PastAnnouncement({newAnnPosted}) {
  const [announcements, setAnnouncements] = useState([]);
  const user = useAuthStore((state) => state.user);
  const [isDP, setIsDP] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentAnnId, setCurrentAnnId] = useState();
  const [currentTextContent, setCurrentTextContent] = useState();
  const [newTextContent, setNewTextContent] = useState();
  const [editData, setEditData] = useState();
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleCloseEditModal = () => setShowEditModal(false);
  const handleShowEditModal = (id, text) => {
    setShowEditModal(true);
    setCurrentAnnId(id);
    setCurrentTextContent(text);
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = (id) => {
    setShowDeleteModal(true);
    setCurrentAnnId(id);
  };

  useEffect(() => {
    if (user && user.id) {
      console.log("userrole: " + JSON.stringify(user, null, 2))
      setIsDP(user.roleBriefs.some(item => item.id === "" || item.name === "NEB Data Provider"))
    }
  }, [user]);

  const fetchAnnouncements = async () => {
    const result = await getAnnouncements();
    setAnnouncements(result);
    console.log("getanns: " + JSON.stringify(result, null, 2))
  }

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  useEffect(() => {
    console.log("newAnnPosted: " + newAnnPosted)
    fetchAnnouncements();
  }, [newAnnPosted])

  // Function to toggle the visibility of the dropdown menu for a specific card
  const toggleMenu = (eventId) => {
    setAnnouncements(
      announcements.map((event) =>
        event.id === eventId ? { ...event, showMenu: !event.showMenu } : event
      )
    );
  };

  const handleDelete = async (id) => {
    setDeleteLoading(true);
    try {
      const result = await deleteAnnouncement(id);
      if (result.status === 200) {
        toast.success("Announcement deleted successfully");
      } else {
        toast.error("Could not delete announcement. " + result.status)
      }
    } catch (error) {
      toast.error("Could not delete announcement. " + error)
    } finally {
      setDeleteLoading(false);
      fetchAnnouncements();
      handleCloseDeleteModal();
    }
  };

  const handleEdit = async (id, newAnn) => {
    setEditLoading(true);
    try {
      const result = await updateAnnouncement(id, newAnn); // Await the result of the async call
      console.log("result update: " + JSON.stringify(result, null, 2))
      toast.success("Announcement edit success.")
    } catch (error) {
      toast.error("Error editing announcement:", error);
    } finally {
      setEditLoading(false); // Stop loading in both success and error cases
      fetchAnnouncements();
      handleCloseEditModal();
    }
  };

  const handleTextChange = (text) => {
    setEditData(
      {
        authorName: user.alternateName,
        authorId: user.id,
        textContent: text
      }
    )
  }

  useEffect(() => {
    console.log("editData: " + JSON.stringify(editData, null, 2))
  }, [editData])


  return (
    <div>
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Announcement</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: '0' }}>
          <Card className="mb-1" style={{ boxShadow: '0px 0px 0px 0px' }}>
            <Card.Body className="">
              <div>
                {/* <Card.Title> */}
                <div className="d-flex align-items-center">
                  {/* User Profile Icon */}
                  {/* <FontAwesomeIcon
                      icon={faUserCircle}
                      className="rounded-circle mr-3"
                      style={{ width: "50px", height: "50px", fontSize: "50px" }}
                    /> */}
                  <div>
                    {/* <h5 className="fw-bold mb-0 p-1">{user.alternateName}</h5> */}
                    {/* <small className="p-1 badge bg-primary" onClick={onBadgeClick}>
                        <FontAwesomeIcon color="white" icon={faUsers} />
                        <span style={{ color: 'white' }}> All</span>
                      </small> */}
                  </div>
                </div>
                {/* </Card.Title> */}
                <Card.Text>
                  <br />
                  <p>Old</p>
                  <textarea
                    className="form-control mb-2"
                    id="postAnnouncementOld"
                    rows="auto"
                    value={currentTextContent}
                    disabled
                    style={{
                      color: '#636363',
                      resize: 'none'
                    }}
                  ></textarea>
                  <p>New</p>
                  <textarea
                    className="form-control mb-2"
                    id="postAnnouncementNew"
                    rows="3"
                    placeholder="Type here..."
                    onChange={(e) => handleTextChange(e.target.value)}
                  ></textarea>
                  <div className="d-flex justify-content-between align-items-center">
                    {/* <div>
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className="mr-4"
                    onClick={() => setShowCalendarModal(true)}
                  />
                  <FontAwesomeIcon icon={faPaperclip} className="mr-4" />
                  <FontAwesomeIcon icon={faImage} className="mr-4" />
                  <FontAwesomeIcon icon={faRecordVinyl} className="mr-4" />
                </div> */}
                  </div>
                </Card.Text>
              </div>
            </Card.Body>

            {/* Calendar Modal */}
            {/* <CreateEvent show={showCalendarModal} onHide={() => setShowCalendarModal(false)} /> */}
          </Card>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleEdit(currentAnnId, editData)} disabled={editLoading}>
            {editLoading ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>


      {announcements.map(ann => (
        <Card style={{ width: 'auto', boxShadow: '0px 0px 0px 0px' }} className="card mb-3">
          <Card.Body>
            <Card.Title>
              <div className="d-flex align-items-center">
                {/* User Profile Icon */}
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className="rounded-circle mr-3"
                  style={{ width: "50px", height: "50px", fontSize: "50px" }}
                />

                {/* User Details: Name and Role */}
                <div>
                  <h5 className="fw-bold mb-0 p-1">{ann.authorName}</h5>

                  {/* Badge with an Icon */}
                  <small className="p-1 badge bg-primary">
                    <FontAwesomeIcon color='white' icon={faUsers} />
                    <span style={{ color: 'white' }}> All</span>
                  </small>
                </div>
              </div>
            </Card.Title>
            <Dropdown align="end" className="position-absolute" style={{ top: '10px', right: '10px' }}>
              <Dropdown.Toggle as={Button} variant="" className="p-0">
                {!isDP && <span>
                  <FontAwesomeIcon icon={faEllipsisH} size="lg" />
                </span>
                }
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleShowEditModal(ann.id, ann.textContent)} ><FontAwesomeIcon icon={faEdit} size="lg" /> Edit</Dropdown.Item>
                <Dropdown.Item onClick={() => handleShowDeleteModal(ann.id)} ><FontAwesomeIcon icon={faTrash} size="lg" /> Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Card.Text>
              <p className="mt-4 fw-bold text-secondary mb-2">
                {/* {event.eventDate} {event.eventTime} */}
              </p>
              <p>{ann.textContent}</p>
            </Card.Text>
          </Card.Body>
          <hr />
        </Card>
      ))}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header>
          Delete Announcement
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this Announcement?
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleDelete(currentAnnId)} disabled={deleteLoading}>
            {deleteLoading ? 'Deleting...' : 'Confirm'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PastAnnouncement;
