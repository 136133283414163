import React, { useEffect, useState } from "react";
import { getSubmissionList } from "../../../api/nebReportService";
import { ClaySelect } from '@clayui/form';
import RadialChart from "./GaugeChart";
import GaugeChart from "./GaugeChart";
import PostAnnouncement from "./PostAnnouncement";
import PastAnnouncement from "./PastAnnouncement";
import AgencyBySector from "./AgencyBySector";
import CustomLegend from "./CustomLegend";

const FormsStatus = () => {

    const [reports, setReports] = useState();
    const [selectedYear, setSelectedYear] = useState();
    const [statusCounts, setStatusCounts] = useState({});
    const [filteredData, setFilteredData] = useState();
    const [yearList, setYearList] = useState([]);
    const [q1GaugeData, setQ1GaugeData] = useState();
    const [q2GaugeData, setQ2GaugeData] = useState();
    const [q3GaugeData, setQ3GaugeData] = useState();
    const [q4GaugeData, setQ4GaugeData] = useState();
    const [totalSubmissionThatYear, setTotalSubmissionThatYear] = useState();
    const [newAnnPosted, setNewAnnPosted] = useState(0);


    //get current year and quarter
    const date = new Date();
    const currentYear = date.getFullYear();

    //get all reports
    useEffect(() => {
        const fetchList = async () => {
            try {
                const result = await getSubmissionList();

                //filter out Draft status reports
                const noDraft = result.data.filter(report => report.reportStatus !== "Draft");
                console.log("ver result: " + JSON.stringify(noDraft, null, 2));
                setReports(noDraft);
            } catch (error) {
                console.error('Error fetching list:', error);
            } finally {
                //setListLoading(false);
            }
        };

        fetchList();
        setSelectedYear(currentYear.toString());
        console.log("selectedYear: " + currentYear + typeof (currentYear));
        setYearList([(currentYear - 2).toString(), (currentYear - 1).toString(), (currentYear).toString()]);
    }, []);

    const handleYSelectChange = (event) => {
        setSelectedYear(event.target.value);
    };

    useEffect(() => {
        const countStatuses = () => {
            if (reports && reports.length > 0) {
                const filteredData = reports.filter(report => report.reportPeriodYear === selectedYear);
                setFilteredData(filteredData);

                const counts = filteredData.reduce((acc, report) => {
                    let statusKey;

                    switch (report.reportStatus) {
                        case 'Pending for Approval':
                            statusKey = 'pendingForApproval';
                            break;
                        case 'Pending for Amendment':
                            statusKey = 'pendingForAmendment';
                            break;
                        case 'Approved':
                            statusKey = 'approved';
                            break;
                        default:
                            return acc;
                    }

                    acc[statusKey] = (acc[statusKey] || 0) + 1;
                    return acc;
                }, {});

                setStatusCounts(counts);
            };
        }

        countStatuses();
    }, [reports, selectedYear]);

    useEffect(() => {
        const q1 = getReportsByStatusAndQuarter(filteredData, "Q1")
        setQ1GaugeData(q1);
        const q2 = getReportsByStatusAndQuarter(filteredData, "Q2")
        setQ2GaugeData(q2);
        const q3 = getReportsByStatusAndQuarter(filteredData, "Q3")
        setQ3GaugeData(q3);
        const q4 = getReportsByStatusAndQuarter(filteredData, "Q4")
        setQ4GaugeData(q4);
        console.log("statusco: " + JSON.stringify(statusCounts, null, 2))
        setTotalSubmissionThatYear(calculateSum(statusCounts));
    }, [filteredData, statusCounts]);

    const calculateSum = (data) => {
        return Object.values(data).reduce((sum, value) => sum + value, 0);
    };

    function getReportsByStatusAndQuarter(filteredData, targetQuarter) {
        if (filteredData && filteredData.length > 0) {
            const initialStatusCounts = {
                "Approved": undefined,
                "Pending for Approval": undefined,
                "Pending for Amendment": undefined,
            };

            return filteredData.reduce((acc, report) => {
                if (report.reportQuarter === targetQuarter) {
                    acc[report.reportStatus] = (acc[report.reportStatus] || 0) + 1;
                }
                return acc;
            }, initialStatusCounts);
        }
    }

    return (
        <div>
            <div className="card mb-5" style={{ boxShadow: "0px 0px 0px 0px" }}>
                <div className="card-body d-flex flex-column align-items-center">
                    <div className="col-md-12 d-flex justify-content-end mb-3">
                        <div className="">
                            <label htmlFor="year">Year </label>
                            <ClaySelect id="year" value={selectedYear} onChange={handleYSelectChange}>
                                {yearList.map(year => (
                                    <option key={year} value={year} >
                                        {year}
                                    </option>
                                ))}
                            </ClaySelect>
                        </div>
                    </div>
                    <div className="w-100 profile-details-container">
                        <h3>Form Status</h3>

                        <div className="card shadow-sm mb-4 p-4">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row">
                                    </div>
                                    <div className="row">
                                        <h3>{statusCounts.pendingForApproval ? statusCounts.pendingForApproval : 0}</h3>
                                    </div>
                                    <div className="row">
                                        Pending for Approval
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                    </div>
                                    <div className="row">
                                        <h3>{statusCounts.pendingForAmendment ? statusCounts.pendingForAmendment : 0}</h3>
                                    </div>
                                    <div className="row">
                                        Pending for Amendment
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                    </div>
                                    <div className="row">
                                        <h3>{statusCounts.approved ? statusCounts.approved : 0}</h3>
                                    </div>
                                    <div className="row">
                                        Approved
                                    </div>
                                </div>
                                {/* <div className="col-md-3">
                                    <div className="">
                                        <label htmlFor="year">Year </label>
                                        <ClaySelect id="year" value={selectedYear} onChange={handleYSelectChange}>
                                            {yearList.map(year => (
                                                <option key={year} value={year} >
                                                    {year}
                                                </option>
                                            ))}
                                        </ClaySelect>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="card shadow-sm mb-4 p-4">
                            <div className="">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Submitted Forms by Quarter</h3>
                                    </div>
                                    <div className="col-md-6">
                                        <h3>Post Announcement</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <GaugeChart totalSubmissionThatYear={totalSubmissionThatYear} quarter={"Quarter 1"} gaugeData={q1GaugeData} />
                                    </div>
                                    <div className="col-sm-3">
                                        <GaugeChart totalSubmissionThatYear={totalSubmissionThatYear} quarter={"Quarter 2"} gaugeData={q2GaugeData} />
                                    </div>
                                    <div className="col-md-6">
                                        <PostAnnouncement setNewAnnPosted={setNewAnnPosted} />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <GaugeChart totalSubmissionThatYear={totalSubmissionThatYear} quarter={"Quarter 3"} gaugeData={q3GaugeData} />
                                    </div>
                                    <div className="col-sm-3">
                                        <GaugeChart totalSubmissionThatYear={totalSubmissionThatYear} quarter={"Quarter 4"} gaugeData={q4GaugeData} />
                                    </div>
                                    <div className="col-md-6" style={{ height: '300px', overflowY: 'auto' }}>
                                        <PastAnnouncement newAnnPosted={newAnnPosted} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" style={{textAlign: 'center'}}>
                                        <CustomLegend/>
                                    </div>
                                </div>
                                <br /><br/><br/>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3>Total Agency Registered by Sector</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <AgencyBySector />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default FormsStatus;