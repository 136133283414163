import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { Link } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import ClayAlert from '@clayui/alert';
import DynamicTabs from "./DynamicTabs";
import { getReportById, submitReport, updateReport } from "../../../api/nebReportService";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle, faTrash
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { getAgencyById } from "../../../api/nebAgencyService";

function ViewReport() {
  const [data, setData] = useState([]);
  const { reportId, agencyId, agencyName, paramQuarter, paramYear } = useParams();
  const [showAmendmentDialog, setShowAmendmentDialog] = useState(false);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [graphType, setGraphType] = useState("bar");
  const [activeTabChart, setActiveTabChart] = useState("tab1");
  const [activeTab, setActiveTab] = useState("tabFuelBalanceReport");
  const [chartKey, setChartKey] = useState(0);
  const [combinedStructure, setCombinedStructure] = useState(null);
  const [reportStatus, setReportStatus] = useState("Not Submitted");
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState('info');
  const [isCurrentFormSaved, setIsCurrentFormSaved] = useState(false);
  const [fetchedReport, setFetchedReport] = useState({})
  const [remark, setRemark] = useState();
  const [remarks, setRemarks] = useState([]);
  const currentDateTime = new Date().toLocaleString();
  const [statusAction, setStatusAction] = useState();
  const navigate = useNavigate();
  const [energySector, setEnergySector] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getReportById(reportId);
        console.log('FETCHING REPORT' + JSON.stringify(result, null, 2));
        setFetchedReport(result.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    console.log("agencyId: " + agencyId)
  }, []);

  //get agency with liferay id
  const getAgency = async (id) => {
    try {
      const response = await getAgencyById(id);
      return response;
    } catch (error) {
      console.error('Error getting agency:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchAgency = async () => {
      if (agencyId) {  // Ensure lifeId is set before fetching agency
        try {
          const agency = await getAgency(agencyId);
          setEnergySector(agency.sectorId);
          console.log("getagency: " + JSON.stringify(agency, null, 2));
        } catch (error) {
          console.error('Error fetching agency:', error);
        }
      }
    };

    //fetchAgency();
  }, [agencyId]);


  //console.log("reportid: " + reportId);

  const requestForAmendmentClick = () => {
    setShowAmendmentDialog(true);
  };

  const handleCloseAmendmentDialog = () => {
    setShowAmendmentDialog(false);
  };

  const ApproveClick = () => {
    setShowApproveDialog(true);
  };

  const handleCloseApproveDialog = () => {
    setShowApproveDialog(false);
  };

  const handleSelectTabChart = (tabKey) => {
    setActiveTabChart(tabKey);
    // Increment the chart key to force a refresh
    setChartKey((prevKey) => prevKey + 1);
  };

  const handleSelectTab = (tabKey) => {
    setActiveTab(tabKey);
  };

  //get current year and quarter
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentQuarter = "Q" + Math.ceil((date.getMonth() + 1) / 3);

  const [selectedQuarter, setSelectedQuarter] = useState(currentQuarter);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const handleQSelectChange = (event) => {
    setSelectedQuarter(event.target.value);
  };

  const handleYSelectChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const [showModal, setShowModal] = useState(false);

  function handleShowModal(action) {
    setStatusAction(action)
    setShowModal(true);
  }

  const handleCloseModal = () => setShowModal(false);


  const [dataProvider, setDataProvider] = useState();
  useEffect(() => {
    setDataProvider(fetchedReport.dataProvider);
    console.log("dp: " + JSON.stringify(fetchedReport.dataProvider, null, 2));
    if (fetchedReport.remarks) {
      setRemarks(fetchedReport.remarks);
    }
    setReportStatus(fetchedReport.reportStatus);
  }, [fetchedReport]);

  const [reportMap, setReportMap] = useState({
    reportNo: "",
    reportStatus: reportStatus,
    dataProvider: {
      id: "",
      name: ""
    },
    agency: {
      id: "",
      agencyName: ""
    },
    reportType: {
      reportTypeId: "",
      description: ""
    },
    reportPeriodYear: "",
    reportQuarter: "",
    forms: []
  });

  useEffect(() => {
    if (dataProvider) {
      setReportMap(prevState => ({
        ...prevState,
        dataProvider: {
          id: dataProvider.id,
          name: dataProvider.name
        },
        agency: {
          id: agencyId,
          agencyName: agencyName
        },
        reportPeriodYear: paramYear,
        reportQuarter: paramQuarter
      }));
    }
  }, [dataProvider]);

  const [alertMessage, setAlertMessage] = useState("");

  const tryUpdateReport = async (reportStatus) => {
    try {
      if (combinedStructure) {
        combinedStructure.reportStatus = reportStatus;
        const result = await updateReport(reportId, combinedStructure);
        console.log("result: " + JSON.stringify(result, null, 2));
        if (result.status === 200 && result.data) {

          if (reportStatus === "Approved") {
            toast.success(`Submission by ${agencyName} ${selectedQuarter} ${selectedYear} has been approved.`);
            handleCloseModal();
            setTimeout(() => {
              navigate("/neb-verification/neb-verification-list");
            }, 5000);
          }
          else {
            toast.success(`Sent Request For Amendment to ${agencyName}`);
            handleCloseModal();
            setTimeout(() => {
              navigate("/neb-verification/neb-verification-list");
            }, 5000);
          }
          setAlertType('success');
          //setAlertVisible(true);
          setIsCurrentFormSaved(true);

        } else {
          console.log("not 200: " + result.status);
          setAlertMessage(`Cannot save.`)
          setAlertType('error');
          setAlertVisible(true);
          setIsCurrentFormSaved(false);
        }
      } else {
        console.error('Combined structure is not ready');
      }
    } catch (error) {
      console.error('Error submitting report:', error);
    }

    // setTimeout(() => {
    //   setAlertVisible(false);
    // }, 5000);
  };

  useEffect(() => {
    console.log("Combined Structure: ", JSON.stringify(combinedStructure, null, 2));
  }, [combinedStructure]);

  const toastContainerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1050, // Ensure it's above other elements
    display: 'flex',
    justifyContent: 'center', // Center the alert content
  };

  const toastContentStyle = {
    width: '100%',
    maxWidth: '1200px', // Optional: limit the maximum width
  };

  const handleRemarkChange = (event) => {
    const currentRemark = {
      "id": generateRemarkId(),
      "sender": "Admin",
      "message": event.target.value,
      "date": currentDateTime
    }
    setRemark(currentRemark);
  }

  function generateRemarkId() {
    const characters = '0123456789abcdef'; // Hex characters
    let result = '';
    let length = 24;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    return result;
  }

  const handleSubmitRemark = () => {
    setRemarks([...remarks, remark]);
  }

  const handleDeleteRemark = (id) => {
    setRemarks(prevRemarks => prevRemarks.filter(remark => remark.id !== id))
  }

  useEffect(() => {
    console.log("remarks: " + JSON.stringify(remarks, null, 2))
    setReportMap((prevReportMap) => ({
      ...prevReportMap,
      remarks: remarks // Update only the remarks portion
    }));
  }, [remarks]); // Add any dependency if needed

  return (
    <>
      {/* {alertVisible && (
        <ClayAlert.ToastContainer>
          <ClayAlert displayType={alertType} title={alertType === 'success' ? 'Success' : 'Error'}>
            {`hello`}
          </ClayAlert>
        </ClayAlert.ToastContainer>
      )} */}
      {alertVisible && (
        <div style={toastContainerStyle}>
          <div style={toastContentStyle}>
            <ClayAlert displayType={alertType} title={alertType === 'success' ? 'Success' : 'Error'}>
              {alertMessage}
            </ClayAlert>
          </div>
        </div>
      )}
      {/* year and quarter */}
      {/* <div className="main-search mb-3">
          <div>
            <label htmlFor="year">Year </label>
            <ClaySelect id="year" value={selectedYear} onChange={handleYSelectChange}>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </ClaySelect>
          </div>
          <div>
            <label htmlFor="quarter">Quarter </label>
            <ClaySelect id="quarter" value={selectedQuarter} onChange={handleQSelectChange}>
              <option value="Q1">Q1</option>
              <option value="Q2">Q2</option>
              <option value="Q3">Q3</option>
              <option value="Q4">Q4</option>
            </ClaySelect>
          </div>
        </div> */}
      <div className="card-horizontal-scroll">
        <div className="card-body">
          <DynamicTabs agencyId={parseInt(agencyId)} agencyName={agencyName} fetchedReport={fetchedReport} reportMap={reportMap} setReportMap={setReportMap} selectedQuarter={paramQuarter} selectedYear={paramYear} reportStatus={reportStatus} combinedStructure={combinedStructure} setCombinedStructure={setCombinedStructure} />
          <div className="text-center mt-5 mb-3 d-flex justify-content-end">
            {/* BUTTONS STARTS */}
            <div className="btn-group">
              <Link
                to={`/reportSubmission`}
                className="btn btn-outline-secondary mr-1"
              >
                Cancel
              </Link>
            </div>
            <button
              //onClick={() => tryUpdateReport("Pending for Amendment")}
              onClick={() => handleShowModal("Pending for Amendment")}
              className="btn btn-outline-primary mr-1"
            >
              Request for Amendment
            </button>
            <button disabled={reportStatus === "Approved" ? true : false} onClick={() => handleShowModal("Approved")} className="btn btn-primary mr-1">
              Approve
            </button>
            {/* BUTTONS ENDS */}
            {/* MODAL STARTS */}
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Submission</Modal.Title>
              </Modal.Header>
              <Modal.Body><span>Are you sure you want to set this report to <strong>{statusAction}</strong>?</span>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-outline-secondary mr-1" onClick={handleCloseModal}>
                  Close
                </button>
                <button className="btn btn-primary mr-1" onClick={() => tryUpdateReport(statusAction)}>
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
            {/* MODAL ENDS */}
          </div>
        </div>
      </div>
      <br />
      <div className="card-horizontal-scroll">
        <h3>Remarks</h3>
        {remarks && remarks.length > 0 && remarks.map(remark => (
          <Card className="mb-1">
            <Card.Body className="">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <Card.Title>
                    <div className="d-flex align-items-center">
                      {/* User Profile Icon */}
                      <FontAwesomeIcon
                        icon={faUserCircle}
                        className="rounded-circle mr-3"
                        style={{ width: "30px", height: "30px", fontSize: "30px" }}
                      />
                      <div>
                        <h5 className="mb-0 p-1">{remark.sender}</h5>
                        <h6 className="mb-0 p-1" style={{ fontWeight: 'normal' }}>{remark.date}</h6>
                      </div>
                    </div>
                  </Card.Title>
                  <br />
                  <Card.Text>
                    <p>{remark.message}</p>
                  </Card.Text>
                </div>

                {/* Trash (Delete) Icon */
                  // viewMode !== "draft" &&
                  // <FontAwesomeIcon
                  //   icon={faTrash}
                  //   color="#ffa0a0"
                  //   //className="text-danger"
                  //   style={{ cursor: "pointer", fontSize: "20px" }}
                  //   onClick={() => handleDeleteRemark(remark.id)} // Add your delete function here
                  // />
                }
              </div>
            </Card.Body>
          </Card>

        ))}
        <div className="card-body">
          <div className="input-group input-group-lg mb-2">
            <input
              onChange={(event) => { handleRemarkChange(event) }}
              className="form-control form-control-lg"
              placeholder="Type here..."
            />
            <button onClick={handleSubmitRemark} className="btn btn-primary ml-2">
              Add Remark
            </button>

          </div>
        </div>
      </div>
    </>
  );
}

export default ViewReport;




