import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  Badge
} from "react-bootstrap";
import { Body, Cell, Head, Row as RowClay} from "@clayui/core";
import ClayCard from "@clayui/card";
import ClayButton from "@clayui/button";
import ClayInput from "@clayui/form/lib/Input";
import ClayLayout from "@clayui/layout";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Pagination from "../../../components/Pagination";
import { listPaginatedAgencies } from "../../../api/nebAgencyService";
import { getLookupParameterDetailsByCode } from "../../../api/lookupService";
import "../../../App.css"

const AgencyList = () => {
  const initialPage = 1;
  const initialItemsPerPage = 5;

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);
  const [agencyList, setAgencyList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  // Sorting states
  const [sortColumn, setSortColumn] = useState("createdDate"); 
  const [sortOrder, setSortOrder] = useState("asc"); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await listPaginatedAgencies(
          currentPage - 1,
          itemsPerPage,
          searchTerm
        );

        // Fetch the sector description for each agency
        const agencyListWithSectorDescriptions = await Promise.all(
          result.content.map(async (agency) => {
            try {
              const sectorDescription =
                await getLookupParameterDetailsByCode(
                  agency.sectorId
                );
              return {
                ...agency,
                sectorDescription: sectorDescription.description,
              };
            } catch (error) {
              console.error(
                `Error fetching sector description for agency ${agency.id}:`,
                error
              );
              return { ...agency, sectorDescription: "Unknown" };
            }
          })
        );

        setAgencyList(agencyListWithSectorDescriptions);
        setTotalPages(result.totalPages);
        setTotalItems(result.totalElements); // Ensure totalItems is set
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [currentPage, itemsPerPage, searchTerm]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update search term on input change
    setCurrentPage(1); // Reset to first page when searching
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy h:mm:ss aa");
  };

   // Handle sorting
   const handleSort = (column) => {
    const isAsc = sortColumn === column && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortColumn(column);

    // Sort the agency list based on column and order
    const sortedList = [...agencyList].sort((a, b) => {
      if (a[column] < b[column]) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });
    setAgencyList(sortedList);
  };

  return (
    <>
      <ClayCard className="mb-3" style={{ borderRadius: "20px" }}>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <ClayLayout.Row className="w-100">
            <ClayLayout.Col md={6}>
              <ClayInput.Group>
                <ClayInput.GroupItem>
                  <ClayInput
                    placeholder="Search Agency"
                    aria-label="Search"
                    aria-describedby="button-addon2"
                    value={searchTerm} // Bind input to search term state
                    onChange={handleSearchChange} // Handle input change
                  />
                </ClayInput.GroupItem>
              </ClayInput.Group>
            </ClayLayout.Col>
            <ClayLayout.Col md={6} className="d-flex justify-content-end">
              <Link to="/neb-agency/neb-agency-form-wizard">
                <ClayButton displayType="primary" className="mb-2 mb-md-0">
                  + Add New Agency
                </ClayButton>
              </Link>
            </ClayLayout.Col>
          </ClayLayout.Row>
        </Card.Header>
        <Card.Body className="p-3" style={{ overflowX: "auto" }}>
          <Table striped bordered hover size="sm" className="mb-2">
            <thead className="text-dark">
            <tr>
                <th>No</th>
                <th onClick={() => handleSort("agencyName")}>
                  Agency {sortColumn === "agencyName" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
                </th>
                <th onClick={() => handleSort("sectorDescription")}>
                  Sector {sortColumn === "sectorDescription" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
                </th>
                <th onClick={() => handleSort("createdDate")}>
                  Date Created {sortColumn === "createdDate" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
                </th>
                <th onClick={() => handleSort("status")}>
                  Status {sortColumn === "status" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {agencyList.map((d, i) => (
                <tr key={d.id}>
                  <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                  <td>{d.agencyName}</td>
                  <td>{d.sectorDescription}</td>
                  <td>{formatDate(d.createdDate)}</td>
                  <td>
                    <Badge
                      bg={
                        d.status === "PENDING"
                          ? "warning"
                          : d.status === "ACTIVE"
                          ? "success"
                          : d.status === "DRAFT"
                          ? "secondary"
                          : "danger"
                      }
                    >
                      {d.status === "PENDING"
                        ? "Pending"
                        : d.status === "ACTIVE"
                        ? "Active"
                        : d.status === "DRAFT"
                        ? "Draft"
                        : "Inactive"}
                    </Badge>
                  </td>
                  <td>
                    <Link
                      to={`/agencyById/${d.id}`}
                      className="btn btn-link"
                      style={{ width: "80px" }}
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems} // Pass totalItems to Pagination
            onPageChange={handlePageChange}
            onEntriesChange={handleItemsPerPageChange}
            entriesPerPage={itemsPerPage} // Pass entriesPerPage to Pagination
          />
        </Card.Body>
      </ClayCard>
    </>
  );
};

export default AgencyList;