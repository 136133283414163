import React, { useState } from "react";
import ModuleGuide from "./ModuleGuide";


function TrainingModule() {
  return (
    <>
        <ModuleGuide />
        {/* <DataSubmissionProcess />
        <BalancingGrid />
        <AdditionalFeatures /> */}
    </>
  );
}

export default TrainingModule;
