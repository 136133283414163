import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import profile from "./profile-svgrepo-com.svg";
import useAuthStore from "../../../store/useAuthStore";
import { getAgencyByLiferayId } from "../../../api/nebAgencyService";
import { getDataProviderByAgencyId } from "../../../api/nebDataProviderService";
import DetailsSection from "./DetailsSection";
import { getSubmissionListAgency } from "../../../api/nebReportService";
import { ClaySelect } from '@clayui/form';
import PastAnnouncement from "../admin-dashboard-content/PastAnnouncement";


const FormAndProductList = () => {
    const [profileData, setProfileData] = useState({
        name: 'loading',
        role: 'loading',
        designation: 'loading',
        agency: 'loading',
        email: 'loading',
        phoneNumber: 'loading',
        contactPerson: {
            name: 'loading',
            email: 'loading',
            phoneNumber: 'loading',
        },
        productList: [],
        formList: [],
    });

    const [agencyId, setAgencyId] = useState();
    const user = useAuthStore((state) => state.user); // Zustand store for user data
    const logout = useAuthStore((state) => state.logout); // Zustand logout function
    const [reports, setReports] = useState();
    const [statusCounts, setStatusCounts] = useState({});
    const [selectedYear, setSelectedYear] = useState();
    const [yearList, setYearList] = useState([]);
    const [filteredData, setFileredData] = useState();


    //get current year and quarter
    const date = new Date();
    const currentYear = date.getFullYear();

    useEffect(() => {
        setSelectedYear(currentYear.toString());
        setYearList([(currentYear - 2).toString(), (currentYear - 1).toString(), (currentYear).toString()]);
    }, []);

    useEffect(() => {
        console.log("filteredData: " + JSON.stringify(filteredData, null, 2))
    }, [filteredData]);

    const handleYSelectChange = (event) => {
        setSelectedYear(event.target.value);
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            if (!user?.id) return;

            try {
                const agency = await getAgencyByLiferayId(user.id);
                const dp = await getDataProviderByAgencyId(agency.id);

                setAgencyId(agency.id);
                setProfileData({
                    name: dp.name,
                    role: 'Data Provider', // Example role
                    designation: 'Agency Representative', // Example designation
                    agency: agency.agencyName,
                    email: dp.email,
                    phoneNumber: dp.phoneNo,
                    contactPerson: {
                        name: dp.agency.cpdName,
                        email: dp.agency.cpdEmail,
                        phoneNumber: dp.agency.cpdPhoneNumber,
                    },
                    productList: extractProductList(dp),
                    formList: extractFormFields(dp),
                });
            } catch (error) {
                console.error('Error fetching profile data:', error);
                logout(); // Handle token invalidation
            }
        };

        if (user?.id) {
            fetchProfileData();
        }
    }, [user, logout]);

    useEffect(() => {
        console.log("agency id: " + JSON.stringify(agencyId, null, 2))
    }, [agencyId]);

    useEffect(() => {
        const fetchSubmissionList = async () => {
            if (agencyId) {
                try {
                    const result = await getSubmissionListAgency(agencyId);
                    console.log("agency id: " + JSON.stringify(result.data, null, 2));
                    setReports(result.data);
                } catch (error) {
                    console.error('Error fetching list:', error);
                } finally {
                }
            }
        };

        fetchSubmissionList();
    }, [agencyId]);

    useEffect(() => {
        const countStatuses = () => {
            if (reports && reports.length > 0) {
                const filteredData = reports.filter(report => report.reportPeriodYear === selectedYear);
                setFileredData(filteredData);

                const counts = filteredData.reduce((acc, report) => {
                    let statusKey;

                    switch (report.reportStatus) {
                        case 'Pending for Approval':
                            statusKey = 'pendingForApproval';
                            break;
                        case 'Pending for Amendment':
                            statusKey = 'pendingForAmendment';
                            break;
                        case 'Draft':
                            statusKey = 'draft';
                            break;
                        case 'Approved':
                            statusKey = 'approved';
                            break;
                        default:
                            return acc;
                    }

                    acc[statusKey] = (acc[statusKey] || 0) + 1;
                    return acc;
                }, {});

                setStatusCounts(counts);
            };
        }

        countStatuses();
    }, [reports, selectedYear]);

    const extractProductList = (data) => {
        return data.agency.categories.flatMap(category =>
            category.products.map(product => ({
                productName: product.productName,
            }))
        );
    };

    const extractFormFields = (data) => {
        if (!data?.agency?.forms) return [];

        const formsList = data.agency.forms
            .map(form => {
                const filteredFields = form.formFields.filter(field => !field.formFieldCode.includes('-NA'));
                if (filteredFields.length === 0) return null;

                return {
                    formType: form.formType,
                    formDescription: form.formDescription,
                    formFields: filteredFields.map(field => ({
                        formFieldCode: field.formFieldCode,
                        formFieldName: field.formFieldName
                    }))
                };
            })
            .filter(Boolean);

        if (data.agency.installedCapacity) {
            formsList.push({ formDescription: "Installed Capacity", formFields: [] });
        }
        if (data.agency.powerPlantPerformance) {
            formsList.push({ formDescription: "Power Plant Performance", formFields: [] });
        }
        if (data.agency.netCalorificValue) {
            formsList.push({ formDescription: "Net Calorific Value", formFields: [] });
        }
        return formsList;
    };


    const { name, role, designation, agency, email, phoneNumber, contactPerson, productList, formList } = profileData;

    return (
        <div>
            <div className="card mb-5" style={{ boxShadow: "0px 0px 0px 0px" }}>
                <div className="col-md-12 d-flex justify-content-end mb-3">
                    <div className="">
                        <label htmlFor="year">Year </label>
                        <ClaySelect id="year" value={selectedYear} onChange={handleYSelectChange}>
                            {yearList.map(year => (
                                <option key={year} value={year} >
                                    {year}
                                </option>
                            ))}
                        </ClaySelect>
                    </div>
                </div>
                <div className="card-body d-flex flex-column align-items-center">
                    <div className="w-100 profile-details-container">
                        <h3>Forms Status</h3>

                        <div className="card shadow-sm mb-4 p-4">
                            <div className="row">
                                <div className="col-md-3">

                                    <div className="row">
                                    </div>
                                    <div className="row">
                                        <h3>{statusCounts.pendingForApproval ? statusCounts.pendingForApproval : 0}</h3>
                                    </div>
                                    <div className="row">
                                        Pending for Approval
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="row">
                                    </div>
                                    <div className="row">
                                        <h3>{statusCounts.pendingForAmendment ? statusCounts.pendingForAmendment : 0}</h3>
                                    </div>
                                    <div className="row">
                                        Pending for Amendment
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="row">
                                    </div>
                                    <div className="row">
                                        <h3>{statusCounts.draft ? statusCounts.draft : 0}</h3>
                                    </div>
                                    <div className="row">
                                        Pending for Submission (Draft)
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="row">
                                    </div>
                                    <div className="row">
                                        <h3>{statusCounts.approved ? statusCounts.approved : 0}</h3>
                                    </div>
                                    <div className="row">
                                        Approved
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-sm mb-4 p-4">

                            <div className="row">
                                <div className="col-md-6">
                                    <h3>Submitted Forms</h3>
                                    <table className="table table-bordered w-100">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Quarter</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData && filteredData.length > 0 ? (
                                                filteredData.map((report, index) => (
                                                    <tr key={index}>
                                                        <td>{report.reportQuarter}</td>
                                                        <td>{report.reportStatus}</td>
                                                        <td>
                                                            <div>
                                                                <Link
                                                                    // state={{}}
                                                                    // to={`/neb-data-submission/neb-data-submission-view/draft/${report.id}/${report.agency.agencyName}/${report.reportQuarter}/${report.reportPeriodYear}/${report.reportStatus}/`}
                                                                    // className=""
                                                                    to={`/neb-data-submission/neb-data-submission-list`}
                                                                >
                                                                    View
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="3" className="text-center">Loading</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="col-md-6">
                                    <h3>Latest Announcements</h3>

                                    <PastAnnouncement />
                                </div>

                                <div className="col-md-6">
                                    <p><strong>Product List</strong></p>
                                    <ul className="list-group list-group-flush">
                                        {productList.length ? productList.map((product, index) => (
                                            <div key={index} className="mb-3">
                                                <strong>{index + 1}. {product.productName}</strong>
                                            </div>
                                        )) : <li className="list-group-item">Loading</li>}
                                    </ul>
                                    <br />
                                    <p><strong>Form List</strong></p>
                                    {formList.length ? formList.map((form, index) => (
                                        <div key={index} className="mb-3">
                                            <div><strong>{index + 1}. {form.formDescription}</strong></div>
                                            <ul className="form-field-list list-unstyled">
                                                {form.formFields.map((field, fieldIndex) => (
                                                    <li key={fieldIndex} className="text-muted">
                                                        {field.formFieldCode} ({field.formFieldName})
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )) : <p className="text-muted">Loading</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAndProductList;