import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import {
  getFormTemplateByParentCode,
  getFormTemplatesByCodes,
} from "../../../../api/nebFormTemplateService";
import {
  deleteFormAndFormFieldsByAgencyId,
  saveAgencyForm,
} from "../../../../api/nebAgencyFormService";
import { toast, Bounce } from "react-toastify";
import { saveAgencyFormField } from "../../../../api/nebAgencyFormFieldService";
import {
  getAgencyById,
  updateColumnAgencyById,
} from "../../../../api/nebAgencyService";
import { useNavigate } from "react-router-dom";

const AgencyForm = ({ agencyId, handleSave, handleBack, setShowSummary }) => {
  // State variables
  const navigate = useNavigate();
  const [agency, setAgency] = useState({});
  const [formDetail, setFormdetail] = useState({});
  const [formListings, setFormListings] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [checkboxStates, setCheckboxStates] = useState({
    c1checkbox: false,
    installedCapacity: false,
    powerPlantPerformance: false,
    netCalorificValue: false,
    manualSubmission: false,
  });

  // Codes to fetch
  const formCodes = [
    "F1",
    "F2",
    "F3",
    "F4",
    "F5",
    "C1RDO",
    "C1COM",
    "C1IND",
    "C1TRP",
    "C1AGR",
    "C1NON",
    "C1UTITNB",
  ];

  // Fetch forms dynamically based on parent codes
  useEffect(() => {
    const fetchFormDetails = async () => {
      try {
        const formDetails = await getFormTemplatesByCodes(formCodes);
        setFormdetail(formDetails);
        console.log(formDetails); // Check the data structure

        const formArray = Object.values(formDetails); // Convert the object to an array of its values
        setFormListings(formDetails); // Save the original formDetails object
        console.log("formArray:", formArray);
        console.log("FormListings:", formListings);

        // Initialize selected values
        const initialSelectedValues = {};
        formArray.forEach((form) => {
          initialSelectedValues[form.code] = [];
        });
        setSelectedValues(initialSelectedValues);
        console.log("initialSelectedValues;", initialSelectedValues);

        // Fetch listings for each form
        formArray.forEach((form) => fetchListing(form.code));
      } catch (error) {
        console.error("Error fetching form details:", error);
      }
    };

    fetchFormDetails();
  }, []);

  useEffect(() => {
    if (agencyId) {
      fetchExistingData(agencyId);
    }
  }, [agencyId]);

  useEffect(() => {
    if (!checkboxStates.c1checkbox) {
      const updatedSelectedValues = { ...selectedValues };
      
      // clear C1 selected value if checkbox C1 is false 
      ["C1RDO", "C1COM", "C1IND", "C1TRP", "C1AGR", "C1NON", "C1UTITNB"].forEach((key) => {
        updatedSelectedValues[key] = [];
      });
  
      setSelectedValues(updatedSelectedValues);
    }
  }, [checkboxStates.c1checkbox]);

  const fetchExistingData = async (agencyId) => {
    try {
      const result = await getAgencyById(agencyId);
      if (result) {
        setShowSummary(true);
      }
      setAgency(result);

      // Update checkbox states
      setCheckboxStates({
        manualSubmission: result.manualSubmission,
        c1checkbox: result.c1checkbox,
        installedCapacity: result.installedCapacity,
        powerPlantPerformance: result.powerPlantPerformance,
        netCalorificValue: result.netCalorificValue,
      });

      // Update selected options based on fetched data
      if (result.forms) {
        const updatedSelectedValues = { ...selectedValues };
        result.forms.forEach((form) => {
          const selectedFormFields = form.formFields.map((field) => ({
            value: field.formFieldCode,
            label: field.formFieldName,
          }));
          updatedSelectedValues[form.formType] = selectedFormFields;
        });
        setSelectedValues(updatedSelectedValues);
        console.log("updatedSelectedValues:", updatedSelectedValues);
      }
    } catch (error) {
      console.error("Error fetching existing data:", error);
    }
  };

  const fetchListing = async (key) => {
    try {
      console.log("key:", key);
      const result = await getFormTemplateByParentCode(key);
      setFormListings((prev) => ({ ...prev, [key]: result }));
      console.log("FormListingss", formListings);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = (selectedOptions, key) => {
    setSelectedValues((prev) => ({ ...prev, [key]: selectedOptions }));
    console.log("selected values:", selectedValues);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxStates((prev) => ({ ...prev, [name]: checked }));
  };

  const onSaveClickForm = async () => {
    try {
      // Prepare agency data
      await updateColumnAgencyById(agencyId, {
        ...checkboxStates,
      });

      const formsToSave = Object.keys(selectedValues)
        .map((key) => {
          const selected = selectedValues[key];
          if (selected.length) {
            return {
              agencyId,
              formType: key,
              formDescription: formDetail[key]?.name,
            };
          }
          return null;
        })
        .filter(Boolean);

      // Delete existing forms and save new ones
      await deleteFormAndFormFieldsByAgencyId(agencyId);
      const savedForms = await saveAgencyForm(formsToSave);

      // Prepare and save form fields
      const formFieldList = savedForms.flatMap((form) => {
        const selectedOptions = selectedValues[form.formType] || [];
        return selectedOptions.map((option) => ({
          agencyFormId: form.id,
          formFieldCode: option.value,
          formFieldName: option.label,
        }));
      });

      await saveAgencyFormField(formFieldList);
      toast("The details have been saved!", {
        position: "top-center", // Adjust as needed
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce, // Ensure Bounce is imported
      });
      setShowSummary(true);
      setTimeout(() => handleSave(agencyId), 2000);
    } catch (error) {
      console.log("Error saving the form fields", error);
      toast.error("Error saving the details");
    }
  };

  const onCancelClick = () => {
    const confirmation = window.confirm("Are you sure you want to cancel this process?");
    if (confirmation) {
      toast('The process have been cancel', {
        position: "top-center", // Adjust as needed
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce, // Ensure Bounce is imported
      });
      setTimeout(() => {
        navigate(`/neb-agency`);
      }, 2000);
    }
  };

  return (
    <Card>
      <Card.Header className="d-flex flex-column align-items-start">
        <div className="d-flex justify-content-between w-100 mb-3">
          <Card.Title as="h2" className="mb-0">
            <b>Form</b>
            <p>
              Select form for Data Provider, Fields marked with
              <span className="text-danger"> * </span>
              are required
            </p>
          </Card.Title>
        </div>
      </Card.Header>
      <Card.Body>
        <b>Manual Submission</b>
        <Form>
          <Form.Group as={Row}>
            <Col sm={12}>
              <div className="d-flex">
                <Form.Check
                  type="radio"
                  label="Don't Allow"
                  name="manualSubmission"
                  id="manualSubmissionNo"
                  className="mr-3"
                  checked={!checkboxStates.manualSubmission}
                  onChange={handleCheckboxChange}
                />
                <Form.Check
                  type="radio"
                  label="Allow"
                  name="manualSubmission"
                  id="manualSubmissionYes"
                  className="mr-3"
                  checked={checkboxStates.manualSubmission}
                  onChange={handleCheckboxChange}
                />
              </div>
            </Col>
          </Form.Group>
        </Form>

        <Form className="mt-3">
          <b>Energy, Supply, Production and Generation</b>
          <Form.Group as={Row} className="mt-3">
            {["F1", "F2", "F3", "F4", "F5"].map((key) => (
              <Col sm={6} key={key} className="mt-3">
                <Form.Label className="font-weight-normal">
                  {formDetail[key]?.name}{" "}
                  <span className="text-danger"> *</span>
                </Form.Label>
                <Select
                  isMulti
                  name={`selected${key}`}
                  value={selectedValues[key]}
                  onChange={(selectedOptions) =>
                    handleSelectChange(selectedOptions, key)
                  }
                  options={
                    formListings[key]?.map
                      ? formListings[key].map((form) => ({
                          value: form.code,
                          label: form.description,
                        }))
                      : []
                  }
                  aria-label={`Select ${key}`}
                />
              </Col>
            ))}
          </Form.Group>
        </Form>

        <Col className="mt-4">
          <Form.Check
            label="C1 : Final Energy Consumption Sectors"
            type="checkbox"
            name="c1checkbox"
            id="c1checkbox"
            checked={checkboxStates.c1checkbox}
            onChange={handleCheckboxChange}
          />
        </Col>

        {checkboxStates.c1checkbox && (
          <Form className="mt-3">
            <Form.Group as={Row} className="mt-3">
              {[
                "C1RDO",
                "C1COM",
                "C1IND",
                "C1TRP",
                "C1AGR",
                "C1NON",
                "C1UTITNB",
              ].map((key) => (
                <Col sm={6} key={key} className="mt-3">
                  <Form.Label className="font-weight-normal">
                    {formDetail[key]?.name}{" "}
                  </Form.Label>
                  <Select
                    isMulti
                    name={`selected${key}`}
                    value={selectedValues[key]}
                    onChange={(selectedOptions) =>
                      handleSelectChange(selectedOptions, key)
                    }
                    options={
                      formListings[key]?.map
                        ? formListings[key].map((form) => ({
                            value: form.code,
                            label: form.description,
                          }))
                        : []
                    }
                    aria-label={`Select ${key}`}
                  />
                </Col>
              ))}
            </Form.Group>
          </Form>
        )}

        {/* Additional forms checkboxes */}
        <Card.Footer className="border-top">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="additionalForms">
                Additional Forms
              </Form.Label>
              {[
                { label: "Installed Capacity", name: "installedCapacity" },
                {
                  label: "Power Plant Performance",
                  name: "powerPlantPerformance",
                },
                { label: "Net Calorific Value", name: "netCalorificValue" },
              ].map((form, index) => (
                <Form.Check
                  key={index}
                  label={form.label}
                  type="checkbox"
                  name={form.name}
                  id={`checkbox${index + 1}`}
                  checked={checkboxStates[form.name]}
                  onChange={handleCheckboxChange}
                />
              ))}
            </Form.Group>
          </Form>
        </Card.Footer>

        <div className="d-flex justify-content-end mt-3">
          <Button
            variant="light"
            className="mr-2 btn btn-outline-secondary"
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            variant="link"
            className="mr-2 btn btn-outline-primary"
            onClick={onCancelClick}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={onSaveClickForm}
            disabled={
              !['F1', 'F2', 'F3', 'F4', 'F5'].every(
                (key) => selectedValues[key] && selectedValues[key].length > 0
              )
            }
            
            
          >
            Save
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AgencyForm;
