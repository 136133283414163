import axiosInstance from "./axiosInstance";

const endpoint = "/neb/agency";

// Save agency
export const saveAgency = async (agency) => {
  try {
    const response = await axiosInstance.put(`${endpoint}/save`, agency);
    return response.data;
  } catch (error) {
    console.error("Error saving agency:", error);
    throw error;
  }
};

// Send activation link
export const sendActivationLink = async (agency) => {
  try {
    const response = await axiosInstance.put(
      `${endpoint}/send-activation`,
      agency
    );
    return response.data;
  } catch (error) {
    console.error("Error saving agency:", error);
    throw error;
  }
};

// Fetch paginated agencies with search term
export const listPaginatedAgencies = async (
  page = 0,
  size = 10,
  searchTerm = ""
) => {
  try {
    const response = await axiosInstance.get(`${endpoint}/paginated`, {
      params: {
        page,
        size,
        searchTerm,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching paginated agencies:", error);
    throw error;
  }
};

// Get agency by ID
export const getAgencyById = async (id) => {
  try {
    const response = await axiosInstance.get(`${endpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching agency by id:", error);
    throw error;
  }
};

// Fetch paginated NEB agencies
export const getPaginatedNebAgencies = async (pageable) => {
  try {
    const response = await axiosInstance.post(`${endpoint}/paginated`, {
      pageable,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching paginated NEB agencies:", error);
    throw error;
  }
};

// Update agency column by ID
export const updateColumnAgencyById = async (id, updatedFields) => {
  try {
    const response = await axiosInstance.put(
      `${endpoint}/updateColumn/${id}`,
      updatedFields
    );
    return response.data;
  } catch (error) {
    console.error("Error updating agency:", error);
    throw error;
  }
};

// Update agency status by ID
export const updateStatusAgencyById = async (id, updatedStatus) => {
  try {
    const response = await axiosInstance.put(
      `${endpoint}/updateStatus/${id}`,
      updatedStatus
    );
    return response.data;
  } catch (error) {
    console.error("Error updating agency status:", error);
    throw error;
  }
};

// Get agency by Liferay ID
export const getAgencyByLiferayId = async (id) => {
  try {
    const response = await axiosInstance.get(
      `${endpoint}/getAgencyByLiferayId/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching agency by Liferay ID:", error);
    throw error;
  }
};

// Count agencies by sector ID
export const countAgenciesBySectorId = async (sectorId) => {
  try {
    const response = await axiosInstance.get(`${endpoint}/count-by-sector-id`, {
      params: { sectorId },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching agency count by sector ID:", error);
    throw error;
  }
};

