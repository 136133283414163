import axiosInstance from './axiosInstanceFileSystem';

const endpoint = "/files";

export const createFolder = async (folderPath) => {
    try {
        const response = await axiosInstance.post(`${endpoint}/create-folder`, null, {
            params: { folderPath },
        });
        return response.data;
    } catch (error) {
        console.error('Error creating folder:', error);
        throw error;
    }
};

export const uploadFile = async (folderPath, file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axiosInstance.post(`${endpoint}/upload-file`, formData, {
            params: { folderPath },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
};

export const deleteFile = async (filePath) => {
    try {
        const response = await axiosInstance.delete(`${endpoint}/delete-file`, {
            params: { filePath },
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting file:', error);
        throw error;
    }
};

export const deleteFolder = async (folderPath) => {
    try {
        const response = await axiosInstance.delete(`${endpoint}/delete-folder`, {
            params: { folderPath },
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting folder:', error);
        throw error;
    }
};

export const listFolders = async (folderPath) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/list-folders`, {
            params: { folderPath },
        });
        return response.data;
    } catch (error) {
        console.error('Error listing folders:', error);
        throw error;
    }
};

export const listFiles = async (folderPath) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/list-files`, {
            params: { folderPath },
        });
        return response.data;
    } catch (error) {
        console.error('Error listing files:', error);
        throw error;
    }
};

export const downloadFile = async (filePath) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/download-file`, {
            params: { filePath },
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filePath.split('/').pop());
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        console.error('Error downloading the file:', error);
        throw error;
    }
};


