import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "react-switch";
import {
  getDataProviderById,
  updateDataProvider,
  getActiveDataProviderByAgencyId,
  listAgencyName,
  saveDataProvider,
} from "../../../api/nebDataProviderService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const CreateNewDataProvider = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [agencyListing, setAgencyListing] = useState([]);
  const [dataProviderById, setDataProviderById] = useState(null);
  const [agencyName, setAgencyName] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  // toggle
  const [checked, setChecked] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("active");

  useEffect(() => {
    if (id) {
      fetchDataProviderDetails(id);
    }
  }, [id]);

  const fetchDataProviderDetails = async (id) => {
    try {
      const result = await getDataProviderById(id);
      setDataProviderById(result);
      setDataProvider({
        userId: result.userId || "",
        agencyId: result.agencyId || "",
        agencyName: result.agency.agencyName || "", 
        name: result.name || "",
        email: result.email || "",
        phoneNo: result.phoneNo || "",
        status: result.status || "",
      });

      const isActive = result.status === "ACTIVE";
      setChecked(isActive);
      setSelectedLabel(isActive ? "active" : "inactive");
    } catch (error) {
      console.log(error);
    }
  };

  const [dataProvider, setDataProvider] = useState({
    agencyId: "",
    name: "",
    email: "",
    phoneNo: "",
    status: "PENDING",
    userId: id ? id : "",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!dataProvider.agencyId) newErrors.agencyId = "Agency is required";
    if (!dataProvider.name) newErrors.name = "Name is required";
    if (!dataProvider.email) newErrors.email = "Email is required";
    if (!dataProvider.phoneNo) newErrors.phoneNo = "Phone number is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCreateOrUpdateDataProvider = async () => {
    if (!validate()) {
      console.log("Validation failed");
      return;
    }

    // Update status based on selectedLabel only if updating
    const updatedDataProvider = {
      ...dataProvider,
      status: id
        ? selectedLabel === "active"
          ? "ACTIVE"
          : "INACTIVE"
        : "PENDING",
    };

    if (id && dataProvider.status !== "PENDING") {
      updateDataProvider(updatedDataProvider)
        .then((response) => {
          console.log("Data Provider updated successfully:", response);
          toast("Data Provider have been updated successfully!", {
            position: "top-center", // Adjust as needed
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce, // Ensure Bounce is imported
          });
          setTimeout(() => {
            navigate(`/dataProviderById/${id}`);
          }, 2000);
        })
        .catch((error) => {
          console.error("Error updating data provider:", error);
          toast.error("Failed to updating data provider.", {
            position: "top-center", // Adjust as needed
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce, // Ensure Bounce is imported
          });
        });
    } else {
      const canSave = await checkActiveDataProvider();
      if (!canSave) {
        return;
      }

      saveDataProvider(updatedDataProvider)
        .then((response) => {
          console.log("Data Provider saved successfully:", response);

          if (response.userId) {
            setDataProvider(response);
            toast("Data Provider have been saved successfully!", {
              position: "top-center", // Adjust as needed
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce, // Ensure Bounce is imported
            });
            setTimeout(() => {
              navigate(`/neb-data-provider/neb-data-provider-list`);
            }, 2000);
          } else {
            console.error("Response data or userId is missing", response);
            toast.error("Failed to save Data Provider details.", {
              position: "top-center", // Adjust as needed
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce, // Ensure Bounce is imported
            });
          }
        })
        .catch((error) => {
          console.error("Error saving data provider:", error);
          toast.error("Failed to save Data Provider details.", {
            position: "top-center", // Adjust as needed
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce, // Ensure Bounce is imported
          });
        });

      console.log("Saving data provider with:", updatedDataProvider);
    }
  };

  const handleCancelDataProvider = () => {
    const confirmation = window.confirm(
      "Are you sure you want to cancel this process?"
    );
    if (confirmation) {
      toast("The process have been cancel", {
        position: "top-center", // Adjust as needed
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce, // Ensure Bounce is imported
      });
      setTimeout(() => {
        navigate(`/neb-data-provider/neb-data-provider-list`);
      }, 2000);
    }
  };

  const checkActiveDataProvider = async () => {
    try {
      const activeProviders = await getActiveDataProviderByAgencyId(
        dataProvider.agencyId
      );
      if (activeProviders.status === "ACTIVE") {
        setErrorMessage(
          "An active data provider already exists within this agency"
        );
        return false;
      }
      return true;
    } catch (error) {
      console.error("Error checking active data provider:", error);
      toast.error("Failed to check active data provider");
      return true;
    }
  };

  // toggle
  const handleToggle = (nextChecked) => {
    setChecked(nextChecked);
    setSelectedLabel(nextChecked ? "active" : "inactive");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataProvider({ ...dataProvider, [name]: value });

    const newErrors = { ...errors };
    delete newErrors[name];
    setErrors(newErrors);
  };

  useEffect(() => {
    fetchAgencyName();
  }, []);

  const fetchAgencyName = async () => {
    try {
      const result = await listAgencyName();
      console.log("Fetched agencies:", result);

      const sortedAgencies = result.sort((a, b) =>
        a.agencyName.localeCompare(b.agencyName)
      );

      setAgencyListing(sortedAgencies);
    } catch (error) {
      console.log("Error fetching agencies:", error);
    }
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex flex-column align-items-start">
          <div className="d-flex justify-content-between w-100 mb-3">
            <Card.Title as="h2" className="mb-0">
              <b>{id ? "Update Profile" : "Create Profile"}</b>
              <p>
                {id ? "Update all necessary details" : "Fill up all details"}{" "}
                <span className="text-danger">*</span>
              </p>
            </Card.Title>
          </div>
        </Card.Header>
        <Card.Body>
          {dataProvider.status !== "PENDING" && (
            <div className="d-flex justify-content-end mt-3">
              <div style={{ display: "flex", alignItems: "center" }}>
                <label
                  className={
                    selectedLabel === "inactive"
                      ? "mr-2 text-danger"
                      : "mr-2 text-secondary"
                  }
                  onClick={() => setSelectedLabel("inactive")}
                >
                  Inactive
                </label>
                <Switch
                  onChange={handleToggle}
                  checked={checked}
                  className="react-switch"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#007bff"
                />
                <label
                  className={
                    selectedLabel === "active"
                      ? "ml-2 text-success"
                      : "ml-2 text-secondary"
                  }
                  onClick={() => setSelectedLabel("active")}
                >
                  Active
                </label>
              </div>
            </div>
          )}
          <Form className="mt-3">
            <b>Details</b>
            <Form.Group as={Row} className="mt-3">
              <Col sm={3}>
                <Form.Label htmlFor="Agency" className="font-weight-normal">
                  Agency <span className="text-danger">*</span>
                  {id ? (
                    <span className="ml-2" style={{ cursor: "pointer" }}>
                      <i className="fas fa-info-circle" aria-hidden="true"></i>{" "}
                      {/* Icon info */}
                    </span>
                  ) : (
                    <OverlayTrigger
                      placement="right" 
                      overlay={
                        <Tooltip id="tooltip-agency">
                          List of Agency where Data Provider status is Inactive
                        </Tooltip>
                      }
                    >
                      <span className="ml-2" style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          aria-hidden="true"
                        />
                      </span>
                    </OverlayTrigger>
                  )}
                </Form.Label>

                {id ? (
                  <div>
                    {dataProviderById && (
                      <p style={{ color: "grey" }}>
                        {dataProviderById.agency.agencyName}
                      </p>
                    )}
                  </div>
                ) : (
                  <Form.Control
                    as="select"
                    name="agencyId"
                    aria-label="Select Agency"
                    value={dataProvider.agencyId}
                    onChange={handleChange}
                    isInvalid={!!errors.agencyId}
                  >
                    <option value="">Please Select.. </option>
                    {agencyListing.length > 0 &&
                      agencyListing.map((agency) => (
                        <option key={agency.agencyId} value={agency.agencyId}>
                          {agency.agencyName}
                        </option>
                      ))}
                  </Form.Control>
                )}

                <Form.Control.Feedback type="invalid">
                  {errors.agencyId}
                </Form.Control.Feedback>
              </Col>
              <Col sm={3}>
                <Form.Label htmlFor="name" className="font-weight-normal">
                  Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter Name"
                  value={dataProvider.name}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Col>
              <Col sm={3}>
                <Form.Label htmlFor="email" className="font-weight-normal">
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="Enter Email"
                  value={dataProvider.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Col>
              <Col sm={3}>
                <Form.Label htmlFor="phoneNo" className="font-weight-normal">
                  Phone Number <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="phoneNo"
                  placeholder="Enter Phone Number"
                  value={dataProvider.phoneNo}
                  onChange={handleChange}
                  isInvalid={!!errors.phoneNo}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNo}
                </Form.Control.Feedback>
              </Col>

              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-end mt-3">
        <Button
          variant="link"
          className="mr-2 btn btn-outline-primary"
          onClick={handleCancelDataProvider}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="mr-2"
          onClick={handleCreateOrUpdateDataProvider}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default CreateNewDataProvider;
