import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ClayButton from "@clayui/button";
import {downloadFile} from "../../../api/fileSystemService";
import useAuthStore from "../../../store/useAuthStore";

function ModuleGuide() {
  const [visibleModuleGuide, setVisibleModuleGuide] = useState(true);
  const [userAcc, setUserAcc] = useState();
  const [userRole, setUserRole] = useState("");
  const [menuItems, setMenuItems] = useState();

  const user = useAuthStore((state) => state.user);

  const handleDownload = async (filePath) => {
    try {
      console.log("File path: ", filePath);
      await downloadFile(filePath);
      console.log("File downloaded successfully");
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  useEffect(() => {
    const fetchUserAccount = async () => {
      try {
        const uac = user;
        if (uac) {
          console.log("uac: " + JSON.stringify(uac, null, 2));
          setUserAcc(uac);
        }
      } catch (error) {
        console.error("Error fetching user account:", error);
      }
    };

    fetchUserAccount();
  }, [user]);

  useEffect(() => {
    if (userAcc && userAcc.roleBriefs) {
      // Function to check the roles
      const checkUserRole = () => {
        const nebAdmin = userAcc.roleBriefs.find(
          (role) => role.id === 987264 && role.name === "NEB Admin"
        );
        const nebDataProvider = userAcc.roleBriefs.find(
          (role) => role.id === 907344 && role.name === "NEB Data Provider"
        );

        if (nebAdmin && !nebDataProvider) {
          setUserRole("nebadmin");
        } else if (nebDataProvider && !nebAdmin) {
          setUserRole("nebdp");
        } else if (nebAdmin && nebDataProvider) {
          setUserRole("dev");
        }
      };
      // Call the function to check roles when userAcc changes
      checkUserRole();
    }
  }, [userAcc]); // Dependency array includes userAcc to watch for changes

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">
          <b className="tm-header">
            {userRole === "nebadmin"
              ? "User Manual on How to Create New Agency and Data Provider"
              : "User Manual on How to Submit New Data Submission"}
          </b>
        </h3>
        <div className="card-tools">
          {/* <button
            type="button"
            onClick={() => setVisibleModuleGuide(!visibleModuleGuide)}
            className="btn btn-tool"
            title={visibleModuleGuide ? "Collapse" : "Expand"}
          >
            <i
              className={`fas ${visibleModuleGuide ? "fa-minus" : "fa-plus"}`}
            />
          </button> */}
        </div>
      </div>
      <div
        className="card-body"
        style={{ display: visibleModuleGuide ? "block" : "none" }}
      >
        Click
        <Link
          className="btn btn-link"
          onClick={() =>
            handleDownload(
              userRole === "nebadmin"
                ? `admin/trainingModule/moduleGuide/User Manual Admin.pdf`
                : `dataProvider/trainingModule/moduleGuide/User manual Data Provider.pdf`
            )
          }
        >
          <ClayButton displayType="primary" className="mb-2 mb-md-0">
            Download File
          </ClayButton>
        </Link>
        button to read more about the user manual
        {/* <div className="row justify-content-center">
          <div className="col-md-8">
            <video width={400} controls className="mx-auto d-block">
              <source src="mov_bbb.mp4" type="video/mp4" />
            </video>
          </div>
        </div> */}
        {/* <br />
        <br />
        <p>
          <b >Accessing NEB DMS:</b>
          <br /> Open your website browser and type the URL for the MEIH Portal:{" "}
          <i>meih.st.gov.my</i>
          <br />
          Or visit the Suruhanjaya Tenaga website: <i>www.st.gov.my</i> and
          click the MEIH icon.
        </p>
        <p>
          <b>Login:</b>
          <br /> Enter your assigned User ID and Password.
          <br />
          Click Sign in
        </p>
        <p>
          <b>Main Dashboard:</b>
          <br /> Dashboard: View a summary of submitted reports and their
          status.
          <br /> Data Submission: Access the module for submitting quarterly
          reports.
          <br /> Change Password: Update your password.
          <br /> Helpdesk: Seek assistance or provide feedback.
          <br /> Data Trend Analysis: Analyze historical data (optional).
          <br /> Announcement: View important notices (optional).
        </p> */}
      </div>
    </div>
  );
}

export default ModuleGuide;
