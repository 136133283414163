import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
import axios from "axios";
import { Link } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Modal, Button } from 'react-bootstrap';
import ClayAlert from '@clayui/alert';
import DynamicTabsDP from "./DynamicTabsDP";
import { ClaySelect, ClayInput } from '@clayui/form';
import { getReportById, submitReport, updateReport } from "../../../api/nebReportService";
import { getAgencyByLiferayId } from "../../../api/nebAgencyService";
import { getDataProviderByAgencyId } from "../../../api/nebDataProviderService";
import useAuthStore from "../../../store/useAuthStore";
import { toast } from "react-toastify";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle, faTrash
} from "@fortawesome/free-solid-svg-icons";

const SubmitNewReportDP = () => {
  const { reportId, viewMode, paramsAgencyName, paramsQuarter, paramsYear, paramsReportStatus } = useParams();
  const location = useLocation();
  const { yearAndQuarters } = location.state || {}; // Extract yearAndQuarters from state
  const [combinedStructure, setCombinedStructure] = useState(null);
  const [reportStatus, setReportStatus] = useState("Not Submitted");
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState('info');
  const [isCurrentFormSaved, setIsCurrentFormSaved] = useState(false);
  //fb balance and unit checking
  const [fbNotBalanced, setFBNotBalanced] = useState(false);
  const [fbUnitIsMissing, setFbUnitIsMissing] = useState(false);
  const [fuelValuesFBChecking, setFuelValuesFBChecking] = useState();
  const [productAndUnitFBChecking, setProductAndUnitFBChecking] = useState();
  //c1 balance and unit checking
  const [c1NotBalanced, setC1NotBalanced] = useState(false);
  const [c1UnitIsMissing, setC1UnitIsMissing] = useState(false);
  const [fuelValuesC1Checking, setFuelValuesC1Checking] = useState();
  const [productAndUnitC1Checking, setProductAndUnitC1Checking] = useState();
  //net calorific unit checking
  const [netUnitIsMissing, setNETUnitIsMissing] = useState(false);
  const [fuelValuesNETChecking, setFuelValuesNETChecking] = useState();
  const [productAndUnitNETChecking, setProductAndUnitNETChecking] = useState();
  //submit's flag
  const [submitBlocked, setSubmitBlocked] = useState(true);
  //count parent forms
  const [parentFormsCount, setParentFormsCount] = useState(0);
  //count checkedForms
  const [checkedFormsCount, setCheckedFormsCount] = useState(0);

  //get current year and quarter
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentQuarter = "Q" + Math.ceil((date.getMonth() + 1) / 3);

  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const handleQSelectChange = (event) => {
    setSelectedQuarter(event.target.value);
  };
  const handleYSelectChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const [showModal, setShowModal] = useState(false);
  const handleShowConfirmationModal = () => setShowModal(true);
  const handleCloseConfirmationModal = () => setShowModal(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [lifeId, setLifeId] = useState();
  const [agencyId, setAgencyId] = useState();
  const [agencyName, setAgencyName] = useState();
  const [dpID, setDPId] = useState();
  const [dpName, setDPName] = useState();
  const navigate = useNavigate();
  const [yearList, setYearList] = useState([]);
  const [submittedQuarterList, setSubmittedQuarterList] = useState([]);
  const quarterList = ["Q1", "Q2", "Q3", "Q4"]
  const [remark, setRemark] = useState();
  const [remarks, setRemarks] = useState([]);
  const currentDateTime = new Date().toLocaleString();
  const [energySector, setEnergySector] = useState();

  // Get the user object from the Zustand store
  const user = useAuthStore((state) => state.user);

  useEffect(() => {
    if (viewMode !== "draft"){
      toast.info("Select a Quarter");
    }
  }, [viewMode])

  useEffect(() => {
    if (user && user.id) {
      setLifeId(user.id);
    }
    console.log("authstore: " + JSON.stringify(user, null, 2))

    setYearList([(currentYear - 2).toString(), (currentYear - 1).toString(), (currentYear).toString()]);
    console.log("yearAndQuarters: " + JSON.stringify(yearAndQuarters, null, 2))

  }, [user, yearAndQuarters]);


  useEffect(() => {
    if (viewMode === "draft") {
      console.log("detected draft: ");
      setReportStatus(paramsReportStatus);
      setSelectedQuarter(paramsQuarter);
      setSelectedYear(paramsYear);
    }
  }, [paramsReportStatus, paramsQuarter, paramsYear]);

  //if viewMode = draft, need to fetch report and populate non disabled inputs
  const [fetchedReport, setFetchedReport] = useState();

  const [isSubmitPopupDisabled, setIsSubmitPopupDisabled] = useState(true);

  useEffect(() => {
    if (viewMode === "draft") {
      const fetchData = async () => {
        try {
          const result = await getReportById(reportId);
          console.log('FETCHING DRAFT REPORT' + JSON.stringify(result, null, 2));
          setFetchedReport(result.data);
          setRemarks(result.data.remarks);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }
  }, []);

  // Function to handle increment or decrement
  const updateCheckedFormsCount = (change) => {
    setCheckedFormsCount(prevCounter => prevCounter + change);
  };

  //get parent forms count
  useEffect(() => {
    console.log("parent forms count: " + parentFormsCount);
    if (checkedFormsCount === parentFormsCount) {
      setIsSubmitPopupDisabled(false);
    } else {
      setIsSubmitPopupDisabled(true);
    }
  }, [parentFormsCount, checkedFormsCount])

  //get agency with liferay id
  const getAgency = async (id) => {
    try {
      const response = await getAgencyByLiferayId(id);
      return response;
    } catch (error) {
      console.error('Error getting agency:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchAgency = async () => {
      if (lifeId) {  // Ensure lifeId is set before fetching agency
        try {
          const agency = await getAgency(lifeId);
          setAgencyName(agency.agencyName);
          setAgencyId(agency.id);
          setEnergySector(agency.sectorId);
          console.log("getagency: " + JSON.stringify(agency, null, 2));
        } catch (error) {
          console.error('Error fetching agency:', error);
        }
      }
    };

    fetchAgency();
  }, [lifeId]);

  //get data provider with agency id
  const getDP = async (id) => {
    try {
      const response = await getDataProviderByAgencyId(id);
      return response;
    } catch (error) {
      console.error('Error getting agency:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchDP = async () => {
      if (agencyId) {  // Ensure agency is set before fetching dp
        try {
          const dp = await getDP(agencyId);
          setDPName(dp.name);
          setDPId(parseInt(dp.userId));
          console.log("detDP: " + JSON.stringify(agencyId, null, 2));
        } catch (error) {
          console.error('Error fetching agency:', error);
        }
      }
    };

    fetchDP();
  }, [agencyId]);

  const [reportMap, setReportMap] =
    useState({
      reportNo: "",
      reportStatus: reportStatus,
      dataProvider: {
        id: "",
        name: ""
      },
      agency: {
        id: "",
        agencyName: ""
      },
      reportType: {
        reportTypeId: "",
        description: ""
      },
      reportPeriodYear: selectedYear,
      reportQuarter: selectedQuarter,
      forms: [],
      remarks: []
    });

  useEffect(() => {
    if (agencyId && agencyName && dpID && dpName) {
      setReportMap(prevState => ({
        ...prevState,
        dataProvider: {
          id: dpID,
          name: dpName
        },
        agency: {
          id: agencyId,
          agencyName: agencyName
        }
      }));
    }
  }, [agencyId, agencyName, dpID, dpName]);

  const handleRemarkChange = (event) => {
    const currentRemark = {
      "id": generateRemarkId(),
      "sender": dpName,
      "message": event.target.value,
      "date": currentDateTime
    }
    setRemark(currentRemark);
  }

  function generateRemarkId() {
    const characters = '0123456789abcdef'; // Hex characters
    let result = '';
    let length = 24;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    return result;
  }

  const handleSubmitRemark = () => {
    setRemarks([...remarks, remark]);
  }

  const handleDeleteRemark = (id) => {
    setRemarks(prevRemarks => prevRemarks.filter(remark => remark.id !== id))
  }

  useEffect(() => {
    console.log("remarks: " + JSON.stringify(remarks, null, 2))
    setReportMap((prevReportMap) => ({
      ...prevReportMap,
      remarks: remarks // Update only the remarks portion
    }));
  }, [remarks]); // Add any dependency if needed

  const trySubmitReport = async (reportStatus) => {
    try {
      if (combinedStructure) {
        combinedStructure.reportStatus = reportStatus;
        const result = await submitReport(combinedStructure);
        console.log("result: " + JSON.stringify(result, null, 2));
        if (result.status === 200 && result.data) {
          if (reportStatus === "Pending for Approval") {
            //setAlertMessage(`Your ${selectedQuarter} ${selectedYear} Submission has been submitted.`);
            handleCloseConfirmationModal();
            toast.success(`Your ${selectedQuarter} ${selectedYear} Submission has been submitted.`);
            setTimeout(() => {
            }, 5000);
          }
          else {
            toast.success(`Your ${selectedQuarter} ${selectedYear} Submission has been saved as Draft.`);
            setTimeout(() => {
            }, 5000);
          }
          navigate("/neb-data-submission/neb-data-submission-list");
          setIsCurrentFormSaved(true);
        } else {
          console.log("not 200: " + result.status);
          toast.error("Report could not be sent. Error: " + result.status)
          setIsCurrentFormSaved(false);
        }
      } else {
        toast.error("Report could not be sent. Error: 'combinedStructure is not ready")
      }
    } catch (error) {
      toast.error("Report could not be sent. Error: " + error)
    }
  };

  const tryUpdateReport = async (reportStatus) => {
    try {
      if (combinedStructure) {
        combinedStructure.reportStatus = reportStatus;
        const result = await updateReport(reportId, combinedStructure);
        console.log("updating report: " + JSON.stringify(combinedStructure, null, 2));
        if (result.status === 200 && result.data) {
          if (reportStatus === "Pending for Approval") {
            handleCloseConfirmationModal();
            toast.success(`Your ${selectedQuarter} ${selectedYear} Submission has been submitted.`)
          }
          else {
            toast.success(`Your ${selectedQuarter} ${selectedYear} Submission has been saved as Draft.`)
          }
          navigate("/neb-data-submission/neb-data-submission-list");
          setIsCurrentFormSaved(true);
        } else {
          console.log("not 200: " + result.status);
          toast.error("Could not save report. Error: " + result.status)
          setIsCurrentFormSaved(false);
        }
      } else {
        toast.error("Could not save report. Error: combinedStructure is not ready")
      }
    } catch (error) {
      toast.error("Could not save report. Error: " + error)
    }
  };

  useEffect(() => {
    console.log("Combined Structure: ", JSON.stringify(combinedStructure, null, 2));
  }, [combinedStructure]);

  const toastContainerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1050, // Ensure it's above other elements
    display: 'flex',
    justifyContent: 'center', // Center the alert content
  };

  const toastContentStyle = {
    width: '100%',
    maxWidth: '1200px', // Optional: limit the maximum width
  };

  const roundBalancesTo3DP = (fuelValues) => {
    return fuelValues.map(item => ({
      ...item,
      balance: Number(item.balance.toFixed(3)) // Rounding balance to 3 decimal places
    }));
  };

  // fb balance and unit checking
  useEffect(() => {
    if (fuelValuesFBChecking && productAndUnitFBChecking) {
      console.log("in&del: " + JSON.stringify(fuelValuesFBChecking, null, 2));
      console.log("in&del: " + JSON.stringify(productAndUnitFBChecking, null, 2));

      // Check for non-zero balance
      const nonZeroBalance = roundBalancesTo3DP(fuelValuesFBChecking).some((fuel) => fuel.balance !== 0);
      console.log("okey comparing..." + JSON.stringify(roundBalancesTo3DP(fuelValuesFBChecking), null, 2))
      setFBNotBalanced(nonZeroBalance);

      // Check if any product is missing its unit (considering the category values)
      const missingUnit = fuelValuesFBChecking.some((fuel) => {
        // Check if all values in the categories are 0
        const allCategoriesZero = fuel.forms.every((form) =>
          form.categories.every((category) => (category.value ?? 0) === 0)
        );

        // If all category values are zero, means no data. so, unit is not needed, therefore not missing
        if (allCategoriesZero) {
          console.log("unit missing fb: allCategoriesZero");
          return false;
        } else {
          console.log("unit missing fb: NotallCategoriesZero");
          const foundMatch = productAndUnitFBChecking.some(
            (unit) => unit.fieldName === fuel.productName
          );
          if (foundMatch) { return false; }
          else { return true; }
        }

      });

      setFbUnitIsMissing(missingUnit);
    }
  }, [fuelValuesFBChecking, productAndUnitFBChecking]);


  //c1 balance and unit checking
  useEffect(() => {
    if (fuelValuesC1Checking && productAndUnitC1Checking) {
      // Check for non-zero balance
      const nonZeroBalance = roundBalancesTo3DP((fuelValuesC1Checking)).some((fuel) => fuel.balance !== 0);
      setC1NotBalanced(nonZeroBalance);

      // Check if any product is missing its unit (considering the category values)
      const missingUnit = fuelValuesC1Checking.some((fuel) => {
        // Check if all values in the categories are 0
        const allCategoriesZero = fuel.forms.every((form) =>
          form.categories.every((category) => (category.value ?? 0) === 0)
        );

        // If all category values are zero, means no data. so, unit is not needed, therefore not missing
        if (allCategoriesZero) {
          return false;
        } else {
          const foundMatch = productAndUnitC1Checking.some(
            (unit) => unit.fieldName === fuel.productName
          );
          if (foundMatch) { return false; }
          else { return true; }
        }
      });

      setC1UnitIsMissing(missingUnit);
    }
  }, [fuelValuesC1Checking, productAndUnitC1Checking]);

  //net calorific unit checking
  useEffect(() => {
    if (fuelValuesNETChecking && productAndUnitNETChecking) {
      console.log("net received: " + JSON.stringify(fuelValuesNETChecking, null, 2))
      console.log("net received: " + JSON.stringify(productAndUnitNETChecking, null, 2))
      // Check if any product is missing its unit (considering the category values)

      const missingUnit = fuelValuesNETChecking.some((fuel) => {
        // Check if all values in the categories are 0
        const allCategoriesZero = fuel.forms.every((form) =>
          form.categories.every((category) => (category.value ?? 0) === 0)
        );

        // If all category values are zero, means no data. so, unit is not needed, therefore not missing
        console.log("allcategorieszero: " + allCategoriesZero);
        if (allCategoriesZero) {
          return false;
        } else {
          const foundMatch = productAndUnitNETChecking.some(
            (unit) => unit.fieldName === fuel.productName
          );
          if (foundMatch) { return false; }
          else { return true; }
        }

      });

      setNETUnitIsMissing(missingUnit);
    }
  }, [fuelValuesNETChecking, productAndUnitNETChecking]);

  //block submit if any of the conditions is met
  useEffect(() => {
    console.log("unit missing fb: " + fbUnitIsMissing);
    console.log("unit missing c1: " + c1UnitIsMissing);
    console.log("unit missing net: " + netUnitIsMissing);
    if (
      fbNotBalanced ||
      fbUnitIsMissing ||
      c1NotBalanced ||
      c1UnitIsMissing ||
      netUnitIsMissing
    ) {
      console.log("okey fbNotBalanced: " + fbNotBalanced);
      console.log("okey fbUnitIsMissing: " + fbUnitIsMissing);
      console.log("okey c1NotBalanced: " + c1NotBalanced);
      console.log("okey c1UnitIsMissing: " + c1UnitIsMissing);
      console.log("okey netUnitIsMissing: " + netUnitIsMissing);
      setSubmitBlocked(true);
    } else {
      setSubmitBlocked(false);
    }
  }, [fbNotBalanced, fbUnitIsMissing, c1NotBalanced, c1UnitIsMissing, netUnitIsMissing]);

  //set quarter options based on selected year
  useEffect(() => {
    if (yearAndQuarters && yearAndQuarters.length > 0) {
      const quarters = getQuartersByYear(yearAndQuarters, String(selectedYear))
      console.log("submittedqlist: " + JSON.stringify(quarters, null, 2))
      if (quarters && quarters.length > 0) {
        setSubmittedQuarterList(quarters);
      }
    }
  }, [selectedYear, yearAndQuarters]);

  const getQuartersByYear = (data, year) => {
    //console.log("received year: " + typeof (data[2].year) + " " + data[2].year);
    const yearData = data.find(item => item.year === year);

    if (yearData) {
      if (yearData.year === paramsYear && viewMode === "draft") {
        const filteredQuarters = yearData.quarters.filter(item => (item.year === paramsYear));
        return filteredQuarters;
      }
      return yearData.quarters; // Only access quarters if yearData exists
    } else {
      console.log(`Year ${year} not found`);
      return []; // Return an empty array or handle as needed
    }
  };

  useEffect(() => {
    console.log("submittedqlist: " + JSON.stringify(submittedQuarterList, null, 2))
  }, [submittedQuarterList])

  return (
    <>
      {/* {alertVisible && (
        <ClayAlert.ToastContainer>
          <ClayAlert displayType={alertType} title={alertType === 'success' ? 'Success' : 'Error'}>
            {`hello`}
          </ClayAlert>
        </ClayAlert.ToastContainer>
      )} */}
      {alertVisible && (
        <div style={toastContainerStyle}>
          <div style={toastContentStyle}>
            <ClayAlert displayType={alertType} title={alertType === 'success' ? 'Success' : 'Error'}>
              {alertMessage}
            </ClayAlert>
          </div>
        </div>
      )}
      <div title={(viewMode === "draft" ? `Update Data (${paramsQuarter} ${paramsYear})` : "Submit Data")} />
      <div>
        {/* year and quarter */}
        <div className="d-flex justify-content-end">
          <div className="mr-3">
            <label htmlFor="year">Year </label>
            <ClaySelect id="year" value={selectedYear} onChange={handleYSelectChange}>
              {yearList.map(year => (
                <option key={year} value={year} >
                  {year}
                </option>
              ))}
            </ClaySelect>
          </div>
          <div>
            <label htmlFor="quarter">Quarter </label>
            <ClaySelect id="quarter" value={selectedQuarter} onChange={handleQSelectChange}>
              <option value="">Select...</option>
              {quarterList.map(item => (
                submittedQuarterList.includes(item) ?
                  // <option disabled value={item}>{item}</option>
                  null
                  :
                  <option value={item}>{item}</option>
              )
              )}
            </ClaySelect>
          </div>
        </div>
        <div className="card-horizontal-scroll">
          <div className="card-body">
            <DynamicTabsDP
              energySector={energySector}
              viewMode={viewMode}
              fetchedReport={fetchedReport}
              updateCheckedFormsCount={updateCheckedFormsCount}
              setParentFormsCount={setParentFormsCount}
              agencyId={agencyId}
              setFuelValuesNETChecking={setFuelValuesNETChecking}
              setProductAndUnitNETChecking={setProductAndUnitNETChecking}
              setFuelValuesC1Checking={setFuelValuesC1Checking}
              setProductAndUnitC1Checking={setProductAndUnitC1Checking}
              setFuelValuesFBChecking={setFuelValuesFBChecking}
              setProductAndUnitFBChecking={setProductAndUnitFBChecking}
              reportMap={reportMap}
              setReportMap={setReportMap}
              selectedQuarter={selectedQuarter}
              selectedYear={selectedYear}
              reportStatus={reportStatus}
              combinedStructure={combinedStructure}
              setCombinedStructure={setCombinedStructure} />
            <div className="text-center mt-5 mb-3 d-flex justify-content-end">
              {/* BUTTONS STARTS */}
              <div className="btn-group">
                <Link
                  to={`/neb-data-submission/neb-data-submission-list`}
                  className="btn btn-outline-secondary mr-1"
                >
                  Cancel
                </Link>
              </div>
              <button
                disabled={paramsReportStatus === "Approved" ? true : false}
                onClick={() => viewMode === "draft" ? tryUpdateReport("Draft") : trySubmitReport("Draft")}
                className="btn btn-outline-primary mr-1"
              >
                Save As Draft
              </button>
              {/* <button disabled={isSubmitPopupDisabled} onClick={handleShowConfirmationModal} className="btn btn-primary mr-1"> */}
              <button disabled={paramsReportStatus === "Approved" ? true : false} onClick={handleShowConfirmationModal} className="btn btn-primary mr-1">
                Submit
              </button>
              {/* BUTTONS ENDS */}
              {/* MODAL STARTS */}
              <Modal show={showModal} onHide={handleCloseConfirmationModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body><span>You are submitting <strong>{selectedQuarter} {selectedYear}</strong> report on behalf of <strong>{agencyName}</strong> agency.</span><br /><br />

                  {
                    submitBlocked ? <p style={{ "color": "orange" }}> One of the forms is unbalanced or missing units. Make sure everything is filled out completely and try again.</p>
                      : null
                  }

                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-outline-secondary mr-1" onClick={handleCloseConfirmationModal}>
                    Close
                  </button>
                  {/* <button disabled={submitBlocked} className="btn btn-primary mr-1" onClick={() => viewMode === "draft" ? tryUpdateReport("Pending for Approval") : trySubmitReport("Pending for Approval")}>
                    Submit
                  </button> */}
                  <button disabled={false} className="btn btn-primary mr-1" onClick={() => viewMode === "draft" ? tryUpdateReport("Pending for Approval") : trySubmitReport("Pending for Approval")}>
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              {/* MODAL ENDS */}
            </div>
          </div>
        </div>
        <hr />
        <div className="card-horizontal-scroll">
          <h3>Remarks</h3>
          {remarks && remarks.map(remark => (
            <Card className="mb-1">
              <Card.Body className="">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <Card.Title>
                      <div className="d-flex align-items-center">
                        {/* User Profile Icon */}
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          className="rounded-circle mr-3"
                          style={{ width: "30px", height: "30px", fontSize: "30px" }}
                        />
                        <div>
                          <h5 className="mb-0 p-1">{remark.sender}</h5>
                          <h6 className="mb-0 p-1" style={{ fontWeight: 'normal' }}>{remark.date}</h6>
                        </div>
                      </div>
                    </Card.Title>
                    <br />
                    <Card.Text>
                      <p>{remark.message}</p>
                    </Card.Text>
                  </div>

                  {/* Trash (Delete) Icon */
                    viewMode !== "draft" &&
                    <FontAwesomeIcon
                      icon={faTrash}
                      color="#ffa0a0"
                      //className="text-danger"
                      style={{ cursor: "pointer", fontSize: "20px" }}
                      onClick={() => handleDeleteRemark(remark.id)} // Add your delete function here
                    />
                  }
                </div>
              </Card.Body>
            </Card>

          ))}
          <div className="card-body">
            <div className="input-group input-group-lg mb-2">
              <input
                onChange={(event) => { handleRemarkChange(event) }}
                className="form-control form-control-lg"
                placeholder="Type here..."
              />
              <button disabled={paramsReportStatus === "Approved" ? true : false} onClick={handleSubmitRemark} className="btn btn-primary ml-2">
                Add Remark
              </button>

            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default SubmitNewReportDP;




