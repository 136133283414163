import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Body, Table } from "@clayui/core";
import ClayCard from "@clayui/card";
import ClayButton from "@clayui/button";
import ClayDropdown from "@clayui/drop-down";
import ClayIcon from "@clayui/icon";
import { Link } from "react-router-dom";
import { listManualSubmissionByYear } from "../../../api/nebManualSubmissionService";
import { downloadFile } from "../../../api/fileSystemService";
import useAuthStore from "../../../store/useAuthStore";
import { getAgencyByLiferayId } from "../../../api/nebAgencyService";
import { getDataProviderByAgencyId } from "../../../api/nebDataProviderService";

function ManualSubmissionAdmin() {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 10;
  const availableYears = Array.from({ length: 11 }, (_, i) =>
    (startYear + i).toString()
  );

  const [manualSubmissionList, setManualSubmissionList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(
    availableYears[availableYears.length - 1]
  );
  const [activeYearDropdown, setActiveYearDropdown] = useState(false);
  const [sortedList, setSortedList] = useState([]);
  const [dpName, setDpName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await listManualSubmissionByYear(selectedYear);
        
        console.log("updated list result: ", result);
        
        // Menggunakan Promise.all untuk mendapatkan dpName untuk setiap submission
        const updatedList = await Promise.all(
          result.map(async (submission) => {
            console.log("Submission createdBy:", submission.createdBy);
            const agency = await getAgencyByLiferayId(submission.createdBy);
            console.log("updated list agency: ", agency);
            const dp = await getDataProviderByAgencyId(agency.id);
            console.log("updated list dp: ", dp);
            // Menambah dpName kepada setiap submission
            return { ...submission, dpName: dp.name };
          })
        );
        
        setManualSubmissionList(updatedList);
        console.log("updated list: ", updatedList);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, [selectedYear]);
  

  useEffect(() => {
    setSortedList(sortListByQuarter([...manualSubmissionList]));
  }, [manualSubmissionList]);

  const handleYearSelection = (year) => {
    setSelectedYear(year);
    setActiveYearDropdown(false);
  };

  const handleDownload = async (filePath) => {
    try {
      console.log("File path: ", filePath);
      await downloadFile(filePath);
      console.log("File downloaded successfully");
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const sortListByQuarter = (list) => {
    return list.sort((a, b) => {
      return parseInt(a.quarter) - parseInt(b.quarter);
    });
  };

  return (
    <>
      <ClayCard className="mb-3" style={{ borderRadius: "20px" }}>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <div className="ml-0">
            <ClayCard.Description displayType="title" className="mb-0">
              <b>List of Submission</b>
            </ClayCard.Description>
          </div>

          <div className="d-flex ml-auto align-items-center">
            <span className="mr-2">Year:</span>
            <ClayDropdown
              trigger={
                <ClayButton displayType="secondary" className="mr-3">
                  {selectedYear}
                  <ClayIcon symbol="caret-bottom" className="ml-2" />
                </ClayButton>
              }
              active={activeYearDropdown}
              onActiveChange={setActiveYearDropdown}
            >
              <ClayDropdown.ItemList>
                {availableYears.map((year, index) => (
                  <ClayDropdown.Item
                    key={index}
                    onClick={() => handleYearSelection(year)}
                  >
                    {year}
                  </ClayDropdown.Item>
                ))}
              </ClayDropdown.ItemList>
            </ClayDropdown>
          </div>
        </Card.Header>

        <Card.Body className="p-3" style={{ overflowX: "auto" }}>
          {sortedList.length === 0 ? (
            <p>No list file in this year.</p>
          ) : (
            <Table>
              <thead className="text-dark">
                <tr>
                  <th>Quarter</th>
                  <th>Form</th>
                  <th>Data Provider</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {sortedList.map((submission) => (
                  <tr key={submission.id}>
                    <td>{submission.quarter}</td>
                    <td>{submission.fileName}</td>
                    <td>{submission.dpName}</td>
                    <td>
                      <Link
                        className="btn btn-link"
                        onClick={() =>
                          handleDownload(
                            `${submission.filePath.replace(/\\/g, "/")}/${
                              submission.fileName
                            }`
                          )
                        }
                      >
                        Download
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </ClayCard>
    </>
  );
}

export default ManualSubmissionAdmin;
